import React, { useEffect } from 'react';
import { colors } from '@snsw/react-component-library';
import { Breakpoint } from '@rmstransactions/components/Styled';
import styled from 'styled-components';

const DialUiWrapper = styled.div`
  position: relative;
  height: 275px;
  margin-bottom: 1rem;
  margin-top: -1.5rem;

  ${Breakpoint('sm')`
    margin-top: 0;
  `}
`;

const DialWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  min-width: 275px;
  transform: scale3d(0.85, 0.85, 0.85);
  overflow: hidden;

  ${Breakpoint('sm')`
    transform: scale3d(1, 1, 1);
  `}

  .dialWrapper {
    position: relative;
    text-align: center;

    .meterWrapper {
      position: relative;
      margin: auto;
    }

    .meter {
      width: 100%;
      height: 100%;
      transform: rotateX(180deg) rotate(332.5deg);
      transition: all ease 500ms;
    }

    .circle {
      fill: none;
    }

    .outline,
    .mask {
      stroke: ${colors.greyBackground};
      stroke-width: 20;
    }

    .meterRadiusLeft {
      transform: translateX(15px) translateY(-5px);
    }

    .meterRadiusRight {
      transform: translateX(200px) translateY(85px);
    }

    .range {
      stroke-width: 18;
    }

    .meterNeedle {
      height: 48%;
      left: 0;
      margin: auto;
      position: absolute;
      right: 0;
      top: 0%;
      transform-origin: bottom center;
      /* orientation fix */
      transform: rotate(270deg);
      transition: all ease 500ms;

      &::after {
        content: '';
        background-color: ${colors.darkBlue};
        position: absolute;
        height: 18px;
        width: 2px;
        top: 28%;
        border-radius: 5px;
      }
    }

    .maxDemerits {
      position: absolute;
      left: 265px;
      right: 0;
      top: 62%;
      color: ${colors.red};
      font-size: 1.25rem;
      font-weight: 600;

      ${Breakpoint('sm')`
        font-size: 1rem;
      `}
    }
  }

  .dialContentWrapper {
    position: absolute;
    top: 0;
    width: 100%;
    height: 200px;
    padding-top: 100px;
  }

  .currentDemerits {
    color: ${colors.darkBlue};
    font-size: 3rem;
    font-weight: 500;
    line-height: 0.75;
  }

  .dialText {
    top: 170px;
    width: 100%;
    text-align: center;
    color: ${colors.darkBlue};
    font-size: 1.5rem;
  }
`;

const DialTextWrapper = styled.div`
  max-width: 260px;
  margin: auto;
  text-align: center;

  position: absolute;
  left: 0;
  bottom: -10px;
  right: 0;
`;

interface Props {
  currentDemerits: number;
  maxDemerits: number;
}

const DialUi = (props: Props) => {
  const { currentDemerits, maxDemerits } = props;

  useEffect(() => {
    /* Set radius */
    const r: any = 115;
    const circles = document.querySelectorAll('.circle');
    const total_circles = circles.length;

    for (let i = 0; i < total_circles; i++) {
      circles[i].setAttribute('r', r);
    }

    /* Set meter's wrapper dimension */
    const wrapper: any = document.querySelector('.meterWrapper');
    wrapper.style.width = '275px';
    wrapper.style.height = '275px';

    /* Add strokes to circles  */
    const cf = 2 * (Math.PI + 0.9) * r;
    const semi_cf = cf / 2;
    document
      .querySelector('.outline_curves')
      .setAttribute('stroke-dasharray', semi_cf + ',' + cf);
    document
      .querySelector('.range')
      .setAttribute('stroke-dasharray', semi_cf + ',' + cf);
    document
      .querySelector('.outline_ends')
      .setAttribute('stroke-dasharray', 2 + ',' + (semi_cf - 2));
    document
      .querySelector('.mask')
      .setAttribute('stroke-dasharray', semi_cf + ',' + cf);

    /* Bind range slider event*/
    const mask = document.querySelector('.mask');
    const meterNeedle: any = document.querySelector('.meterNeedle');

    const range_change_event = (percent: number = 0) => {
      const meter_value = semi_cf - (percent * semi_cf) / 100;
      const offsetPx = 0;
      mask.setAttribute('stroke-dasharray', meter_value + ',' + cf);
      meterNeedle.style.transform =
        'rotate(' + (240 + offsetPx + (percent * 240) / 100) + 'deg)';
    };

    const calculatePercent = (percent: number, num: number) =>
      (percent * 100) / num;
    const convertPercent = calculatePercent(
      currentDemerits,
      maxDemerits === 0 ? 0.01 : maxDemerits
    );

    range_change_event(convertPercent);
  }, [currentDemerits, maxDemerits]);

  return (
    <DialUiWrapper>
      <DialWrapper>
        <div className="dialWrapper">
          <div className="meterWrapper" aria-hidden="true">
            <svg className="meter">
              <circle
                className="outline_curves circle outline"
                cx="50%"
                cy="50%"
              />
              <circle
                className="circle range"
                cx="50%"
                cy="50%"
                stroke={colors.darkBlue}
              />
              <circle className="mask circle" cx="50%" cy="50%" />
              <circle
                className="outline_ends circle outline"
                cx="50%"
                cy="50%"
              />
              <circle cx="100%" cy="100%" />
              <circle
                className="meterRadiusLeft"
                cx="52"
                cy="52"
                r="9"
                fill={
                  currentDemerits > 0 ? colors.darkBlue : colors.greyBackground
                }
              />
              <circle
                className="meterRadiusRight"
                cx="52"
                cy="52"
                r="9"
                fill={
                  currentDemerits >= maxDemerits
                    ? colors.darkBlue
                    : colors.greyBackground
                }
              />
            </svg>
            <span className="meterNeedle" />
            <span className="maxDemerits">{maxDemerits}</span>
          </div>
          <div className="dialContentWrapper" aria-hidden="true">
            <div className="currentDemerits">{currentDemerits}</div>
            <div className="dialText">demerits</div>
          </div>
        </div>
      </DialWrapper>
      <DialTextWrapper>
        <p>
          Driver licence will be suspended if you gain a total of{' '}
          <strong>{maxDemerits} demerits</strong>
        </p>
      </DialTextWrapper>
    </DialUiWrapper>
  );
};

export default DialUi;
