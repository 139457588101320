import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';

export const ButtonGroup = styled.div`
.conditionalButton {
  margin-bottom: 20px;
  width: 183px;
  height: 44px;
}

.back {
  width: 100%;
  ${Breakpoint('sm')`
    width: 190px;
  `}
  font-weight: 500;
}

.continue {
  width: 100%;
  ${Breakpoint('sm')`
    width: 220px;
  `}
  margin-right: 20px;
}

.mobile-spacer {
  display: block;

  ${Breakpoint('sm')`
    display: none;
  `}
}
`;
export const IconLabelWrapper = styled.div`
  display: flex;
  margin: 0;
  .iconWrapper,
  svg {
    width: 32px;
    height: 32px;
    margin-right: 0.75rem;
  }
  p,a {
    margin: 0.25rem 0 1.25rem 0.25rem;
  }
`;


export const IconLabelDownload = styled.div`
  display: flex;
  margin-bottom: -0.25rem;

  .iconWrapper,
  svg {
    width: 24px;
    height: 24px;
    margin-right: 0.5rem;
  }

  p,
  a {
    margin: 0;
  }
`;
export const ButtonStyle = styled.div`
.button_survey {
    width: 100%;
    ${Breakpoint('sm')`
    width: 200px;
    `} 
  }
.button-get-drivingRecord {
    width: 100%;
    ${Breakpoint('sm')`
    width: 259px;
    `} 
  }
`;

export const PriceDetailsWrapper = styled.div`
  & > div.priceDetails {
    padding: 0 0 8px 0;
    display: flex;
    justify-content: space-between;
    width: 259px;
    .keepRight {
      float: right;
    }
    .totalAmount {
      font-size:18px;
    } 
  }
`;

export const PriceDetailsContainer = styled.div`
  margin-top: -0.75rem;
`;

export const EmailInlineWrapper = styled.div`
.formInputEmail {
  width: 90%;
  margin:0 0 20px 0;
    ${Breakpoint('sm')`
    margin: 0 20px 0 0 ;
    width: 372px;
    `} 
  }
.sendButton {
  width: 100%;
  height: 48px;
    ${Breakpoint('sm')`
      height: 48px;
      width: 216px;
    `}
  }
`;
