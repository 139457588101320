import React from 'react';
import {
    Heading,
    IconClock,
    IconEmail, IconGlobe,
    IconNotificationError,
    IconNotificationSuccess
} from "@snsw/react-component-library";
import {Spacer} from "@rmstransactions/components";
import {IconLabelWrapper, PriceDetailsContainer, PriceDetailsWrapper} from "../../pages/DrivingRecord/styled";

interface Props {
    cdrNetAmount: number;
    cdrGstAmount: number;
    cdrGrossAmount: number;
}

const BuyCertifiedDrivingRecord = (props: Props) => {
    const {cdrNetAmount, cdrGstAmount, cdrGrossAmount} = props
    return (
        <>
            <Heading level={3}>A certified record</Heading>
            <Spacer mb={-0.5} mt={-0.5}>
                <IconLabelWrapper>
                    <div className="iconWrapper">
                        <IconNotificationSuccess/>
                    </div>
                    <p>Can be used for legal proceedings</p>
                </IconLabelWrapper>
                <IconLabelWrapper>
                    <div className="iconWrapper">
                        <IconClock/>
                    </div>
                    <p>Shows a history of offences against your licence</p>
                </IconLabelWrapper>
                <IconLabelWrapper>
                    <div className="iconWrapper">
                        <IconEmail/>
                    </div>
                    <p>Mailed to you within 7 days</p>
                </IconLabelWrapper>
            </Spacer>
            <Heading level={3}>And an uncertified record</Heading>
            <Spacer mb={1.5} mt={-0.5}>
                <IconLabelWrapper>
                    <div className="iconWrapper">
                        <IconNotificationError/>
                    </div>
                    <p>Cannot be used for legal proceedings</p>
                </IconLabelWrapper>
                <IconLabelWrapper>
                    <div className="iconWrapper">
                        <IconClock/>
                    </div>
                    <p>Shows a history of offences against your licence</p>
                </IconLabelWrapper>
                <IconLabelWrapper>
                    <div className="iconWrapper">
                        <IconGlobe/>
                    </div>
                    <p>Access online for 7 days</p>
                </IconLabelWrapper>
            </Spacer>
            <hr/>
            <Spacer mb={1.5}>
                <Heading level={3}>Your order</Heading>
                <PriceDetailsContainer>
                    <PriceDetailsWrapper>
                        <div className="priceDetails">
                            <div data-testid={`priceName`}>
                                Certified driving record
                            </div>
                            <div data-testid={`priceAmount`}>
                                ${(Math.round(cdrNetAmount * 100) / 100).toFixed(2)}
                            </div>
                        </div>
                        <div className="priceDetails">
                            <div data-testid={`priceName`}>GST</div>
                            <div data-testid={`priceAmount`}>
                                ${(Math.round(cdrGstAmount * 100) / 100).toFixed(2)}
                            </div>
                        </div>
                        <div className="priceDetails">
                            <div data-testid={`priceName`}>
                                <strong className="totalAmount">Total cost</strong>
                            </div>
                            <div data-testid={`priceAmount`}>
                                <strong className="totalAmount">
                                    ${(Math.round(cdrGrossAmount * 100) / 100).toFixed(2)}
                                </strong>
                            </div>
                        </div>
                    </PriceDetailsWrapper>
                </PriceDetailsContainer>
            </Spacer>
        </>
    );
}
export default BuyCertifiedDrivingRecord;
