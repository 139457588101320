import { customerDetailsConstants } from 'redux/actions';
import { ICustomerDetailsResponse } from 'interfaces';

export const initialState = {
  status: '',
  primaryEmail: '',
  rmsEmail: '',
  snswEmail: '',
  customerAddress: {}
};

export const customerDetails = (state = initialState, payload: ICustomerDetailsResponse) => {
  switch (payload.type) {
    case customerDetailsConstants.REQUEST_CUSTOMER_DETAILS:
      return {
        ...state,
        status: 'loading',
      }
    case customerDetailsConstants.SUCCESS_CUSTOMER_DETAILS:
      return {
        ...state,
        status: 'success',
        primaryEmail: payload.data.rmsEmail === null
          ? payload.data.snswEmail
          : payload.data.rmsEmail,
        snswEmail: payload.data.snswEmail,
        rmsEmail: payload.data.rmsEmail,
        customerAddress: payload.data.customerAddress,
      }
    case customerDetailsConstants.FAILTURE_CUSTOMER_DETAILS:
      return {
        ...state,
        status: 'error',
      }
  default:
    return state;
  }
};
