import React, { useState } from 'react';
import {
  formMessages,
  validation,
  Spacer,
  DisableTelOnTabletDesktop,
  Svg,
} from '@rmstransactions/components';
import {
  Button,
  Notification,
  IconEmail,
  Modal,
  colors,
  FormGroup,
  Input,
} from '@snsw/react-component-library';
import { EmailContainer } from './EmailForm.styled';
import {
  EmailInlineWrapper,
  IconLabelWrapper,
} from '../../pages/DrivingRecord/styled';
import { sendEmailReceipt } from '../../services/OdrCdrService';
import { ReactComponent as AtEmailIconSVGImage } from '../../assets/at-email-icon.svg';

interface AppEmailFormProps {
  uuid?: string;
  emailInputRef?: any;
}

const EmailForm: React.FC<AppEmailFormProps> = ({ uuid, emailInputRef }) => {
  const [emailValue, setEmailValue] = useState<string>('');
  const [emailErrorMessage, setEmailErrorMessage] = useState<string>('');
  const [hasEmailError, setHasEmailError] = useState<boolean>(false);
  const [showModal, setShowModal] = useState<boolean>(false);
  const [submittedEmail, setSubmittedEmail] = useState<string>('');
  const [emailSubmitStatus, setEmailSubmitStatus] = useState<string>('');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;

    if (value === '') {
      setEmailErrorMessage(formMessages.email.isValid);
    } else {
      setEmailErrorMessage('');
    }

    setEmailValue(value);
    setEmailSubmitStatus('');
    setHasEmailError(false);
  };

  const handleSendButton = () => {
    if (!validation.email(emailValue)) {
      setEmailErrorMessage(formMessages.email.isValid);
      setHasEmailError(true);
      return;
    }
    setShowModal(true);
  };

  const handleCancelButton = () => {
    setShowModal(false);
  };

  const handleSendEmailReceipt = () => {
    if (!validation.email(emailValue)) {
      setEmailErrorMessage(formMessages.email.isValid);
      setHasEmailError(true);
      return;
    }

    const emailRequest = {
      emailAddress: emailValue,
      uuid,
    };
    setEmailSubmitStatus('loading');
    sendEmailReceipt(emailRequest)
      .then((res) => {
        if (res.data.status === 200) {
          setEmailSubmitStatus('success');
          setSubmittedEmail(emailValue);
        } else {
          setEmailSubmitStatus('error');
        }
      })
      .catch((err) => {
        setEmailSubmitStatus('error');
      });
    setShowModal(false);
  };

  return (
    <EmailContainer>
      {showModal && (
        <Modal
          title='Is this email address correct?'
          buttons={[
            { text: 'Send', onClick: handleSendEmailReceipt },
            { text: 'Cancel', onClick: handleCancelButton },
          ]}
        >
          <p>
            For your security, please confirm you'd like your receipt and
            uncertified record sent to{' '}
            <strong className='h-word-break'>{emailValue}</strong>
          </p>
        </Modal>
      )}
      <IconLabelWrapper>
        <div className='iconWrapper'>
          <AtEmailIconSVGImage />
        </div>
        <p>Email your receipt and uncertified record</p>
      </IconLabelWrapper>
      <EmailInlineWrapper>
        <FormGroup
          id='formInputEmail'
          errorMessage={emailErrorMessage}
          hasError={hasEmailError}
        >
          <Input
            onChange={handleEmailChange}
            value={emailValue}
            className='formInputEmail'
            data-testid='formInputEmail'
            inputRef={emailInputRef}
            label=''
            style={{ marginTop: '-10px' }}
          />
        </FormGroup>
      </EmailInlineWrapper>
      <div>
        <Spacer mt={1} />
        <EmailInlineWrapper>
          <Button
            id='sendButton'
            theme='secondary'
            className='sendButton'
            data-testid='sendButton'
            onClick={handleSendButton}
          >
            <>
              <IconEmail />
              <b>
                {emailSubmitStatus === 'loading' ? (
                  <Svg.Spinner color='#646974' />
                ) : (
                  ' Send'
                )}
              </b>
            </>
          </Button>
        </EmailInlineWrapper>
        {emailSubmitStatus === 'success' && (
          <Notification
            variant='success'
            role='status'
            className='notice-wrapper'
            title={
              <p className='h-notice-title'>
                Your email has been sent to{' '}
                <strong className='h-word-break'>{submittedEmail}</strong>
              </p>
            }
          />
        )}
        {emailSubmitStatus === 'error' && (
          <Notification
            variant='error'
            role='alert'
            title={
              <span style={{ fontWeight: 400 }}>
                <span style={{ marginBottom: '0.25rem', display: 'block' }}>
                  <strong>Error.</strong> There was a problem sending your
                  email.
                </span>
                <span>
                  Please try again, call us on{' '}
                  <DisableTelOnTabletDesktop phoneNumber='13 77 88' /> or visit
                  a{' '}
                  <a
                    href='https://www.service.nsw.gov.au/service-centre'
                    style={{ color: `${colors.blue}` }}
                    target='_blank'
                    rel='noopener noreferrer'
                  >
                    service centre
                  </a>
                  .
                </span>
              </span>
            }
          />
        )}
      </div>
    </EmailContainer>
  );
};

export default EmailForm;
