import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';

const ThemeLocal = styled.div`
  a {
    text-decoration: underline;
  }

  // IE 10+ CSS styles go here
  @media all and(-ms - high - contrast: none), (-ms - high - contrast: active) {
    /* unset does not work in IE11 */
    label {
      margin: 0;
    }

    /* unset does not work in IE11 */
    label,
      input,
      select,
      textarea,
    .button,
    .form__item,
    .form__text,
    .form__select,
    .form__textarea,
    .form__item label,
    .form__item p {
      max - width: none;
    }
  }

  .h-notice-title {
    font-weight: 400;

    strong {
      font-weight: 700;
    }
  }

  .notice-wrapper {
    & > div:first-child {
      ${Breakpoint('sm')`
        flex-flow: nowrap;
      `};
    }
  }
`;

export default ThemeLocal;

