import { paymentRequestConstants } from 'redux/actions';

export const initialState = {
  amount: 0,
  kioskId: '',
  transactionType: '',
  redirectUrl: '',
  messages: [
    {
      code: '',
      category: '',
      severity: '',
      message: '',
      messageTitle: '',
      messageDescription: '',
    },
  ],
  processPaymentSelected: false,
};

export const processPaymentRequest = (state = initialState, action: any) => {
  switch (action.type) {
    case paymentRequestConstants.REQUEST_PAYMENT:
      return {
        ...state,
        amount: action.data.amount,
        kioskId: action.data.kioskId,
        transactionType: action.data.transactionType,
      };
    case paymentRequestConstants.SUCCESS_PAYMENT_REQUEST:
      return {
        ...state,
        redirectUrl: action.data.redirectUrl,
        messages: [
          {
            code: action?.data?.messages[0]?.code,
            category: action?.data?.messages[0]?.category,
            severity: action?.data?.messages[0]?.severity,
            message: action?.data?.messages[0]?.message,
            messageTitle: action?.data?.messages[0]?.messageTitle,
            messageDescription:
              action?.data?.messages[0]?.messageDescription,
          },
        ],

        processPaymentSelected: true,
      };
    case paymentRequestConstants.FAILURE_PAYMENT_REQUEST:
      return {
        ...state,
        redirectUrl: action.data.redirectUrl,
        messages: [
          {
            code: action?.data?.messages[0]?.code,
            category: action?.data?.messages[0]?.category,
            severity: action?.data?.messages[0]?.severity,
            message: action?.data?.messages[0]?.message,
            messageTitle: action?.data?.messages[0]?.messageTitle,
            messageDescription:
              action?.data?.messages[0]?.messageDescription,
          },
        ],
        processPaymentSelected: true,
      };
    default:
      return state;
  }
};
