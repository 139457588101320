import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { purchaseSuccessDrivingRecord } from 'redux/actions';
import { useHistory, useLocation, useParams } from 'react-router-dom';
import {
  colors,
  Heading,
  Modal,
  IconExternalLink,
} from '@snsw/react-component-library';
import { ReactComponent as AtEmailIconSVGImage } from 'assets/at-email-icon.svg';
import { Link } from 'react-scroll';
import {
  IconNotificationError,
  IconPrint,
  IconNotificationSuccess,
  IconEmail,
  IconDownload,
  IconCalendar,
  Button,
  Notification,
  ContentContainer,
  Col,
  Row,
  ComponentLoader,
} from '@snsw/react-component-library';
import {
  Header,
  Spacer,
  Quickfeed,
  Svg,
  AnalyticsService,
  helpers,
  DisableTelOnTabletDesktop,
} from '@rmstransactions/components';
import { IconLabelWrapper, ButtonStyle } from './styled';
import { getDrivingRecordDocument } from '../../services/OdrCdrService';
import {
  base64toBlobReceipt,
  getDrivingRecordAccessUntilDate,
  processPdf,
  TransactionType,
  Constants,
} from 'utils/helpers';
import EmailForm from '../../components/EmailForm/EmailForm';
import { interceptors } from '../../services/DemeritPointsService';
import { Config, DRUPAL_DPC_URL } from '../../config/env';
import { ErrorPage } from 'components';
import TimeoutPage from '../TimeoutPage/TimeoutPage';
import { AppState, globalContextCreated } from 'providers/globalState';
import ConfirmationUUIDManager from 'session/ConfirmationUUIDManager';

const PurchasedSuccess = () => {
  const history = useHistory();
  const [documentState, setDocumentState] = useState<string>('');
  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const [showModal, setShowModal] = useState<boolean>(false);

  const urlParams = useParams<{ uuid?: string }>();
  const location = useLocation();
  const locationState = location?.state as confirmationHistoryState;
  const uuid =
    urlParams.uuid ?? locationState?.uuid ?? ConfirmationUUIDManager.getValue();

  const { globalState } = useContext(globalContextCreated) as {
    globalState: AppState;
    globalDispatch: any;
  };

  const handleButtonClickGA = (
    eventName: String,
    eventAction: String,
    destinationUrl: String
  ) => {
    AnalyticsService.ButtonClickUpgrade(
      eventAction,
      eventName,
      globalState.transactionStartTime,
      destinationUrl
    );
  };

  const handleClick = (eventAction: string, eventName: string) => {
    if (eventAction === 'button_link_small') {
      emailInputRef.current.scrollIntoView({ behavior: 'smooth' });
      emailInputRef.current.focus({ preventScroll: true });
    }
    if (eventName === 'Update your address') {
      setShowModal(true);
      handleButtonClickGA(
        eventName,
        eventAction,
        `${process.env.REACT_APP_SHARING_CENTER}`
      );
    } else {
      handleButtonClickGA(eventName, eventAction, pageUrl);
    }
  };
  const dispatch = useDispatch();
  const {
    status,
    isCertified,
    mailingAddress,
    receiptNumber,
    transactionDate,
    emailAddress,
    totalAmountPaid,
    customerName,
    receiptPDF,
    cdrWarning,
  } = useSelector((state: any) => ({
    status: state.purchaseSuccessDrivingRecord?.status,
    isCertified: state.purchaseSuccessDrivingRecord?.isCertified,
    mailingAddress: state.purchaseSuccessDrivingRecord?.mailingAddress,
    receiptNumber: state.purchaseSuccessDrivingRecord?.receiptNumber,
    transactionDate: state.purchaseSuccessDrivingRecord?.transactionDate,
    emailAddress: state.purchaseSuccessDrivingRecord?.emailAddress,
    totalAmountPaid: state.purchaseSuccessDrivingRecord?.totalAmountPaid,
    customerName: state.purchaseSuccessDrivingRecord?.customerName,
    receiptPDF: state.purchaseSuccessDrivingRecord?.receiptPDF,
    cdrWarning: state.purchaseSuccessDrivingRecord?.cdrWarning,
  }));

  const pageUrl =
    window.location.protocol +
    Constants.FORWARD_DOUBLE_SLASH +
    window.location.hostname +
    Constants.PURCHASE_SUCCESS_PAGE_URL +
    uuid;

  useEffect(() => {
    document.title = 'Service NSW - Request Driving Record - Purchased Success';
    let type: string = TransactionType.DPC;
    interceptors(history, Config.apiHost, type, DRUPAL_DPC_URL);
    // @ts-ignore
    dispatch(purchaseSuccessDrivingRecord(uuid));
    AnalyticsService.PageViewUpgrade(
      Constants.PURCHASED_SUCCESS_PAGE,
      pageUrl,
      '',
      globalState.transactionStartTime
    );
  }, []); // eslint-disable-line
  const filename = `uncertified_driving_record_${customerName}_${transactionDate}.pdf`;
  const onDownloadUdrButton = async (
    eventAction: string,
    eventName: string
  ) => {
    handleButtonClickGA(eventName, eventAction, pageUrl);
    setDocumentState('loading');
    getDrivingRecordDocument(uuid)
      .then((response: any) => {
        if (response.data.size === 0) {
          setDocumentState('error');
        } else {
          setDocumentState('');
          processPdf(response.data, filename);
        }
      })
      .catch((error: any) => {
        setDocumentState('error');
        history.push('/error');
      });
  };

  const blobReceipt = base64toBlobReceipt(receiptPDF!);
  const receiptUrl = URL.createObjectURL(blobReceipt);
  const receiptFilename = `Driving Record Receipt_${customerName}_${transactionDate}`;

  const updateAddressUrl = () => {
    window.open(`${process.env.REACT_APP_SHARING_CENTER}`, '_blank');
    setShowModal(false);
  };
  const UpdateButton = (
    <span>
      Update your address <IconExternalLink color='white' />
    </span>
  );
  const onClose = () => setShowModal(false);
  const purchaseSuccessContent = (
    <>
      <TimeoutPage />
      <Header
        data-testid='header'
        headingIcon='TickCircle'
        title='Purchase Successful'
      />
      <ContentContainer>
        {showModal && (
          <Modal
            title='How to update your address'
            buttons={[{ text: UpdateButton, onClick: updateAddressUrl }]}
            onClose={onClose}
          >
            <p>
              1. Select ‘Update your address’ below <br />
              2. Enter your new address within your MyServiceNSW account <br />{' '}
              3. When prompted to choose services, select the 'NSW driver
              licence and vehicle registrations' checkbox.
            </p>
          </Modal>
        )}
        <Row>
          <Col span={7}>
            <Spacer mt={-2.5} />
            <Heading level={2}>You have purchased:</Heading>

            {isCertified && (
              <>
                <Spacer mt={-1} />
                <Heading level={3}>A certified driving record</Heading>
                <Spacer mt={-0.5} />
                <IconLabelWrapper>
                  <div className='iconWrapper'>
                    <IconEmail />
                  </div>
                  <p>
                    We'll mail your certified driving record to
                    {mailingAddress ? (
                      <>
                        <br />
                        <strong>
                          {mailingAddress}
                          <br />
                        </strong>
                      </>
                    ) : (
                      <>&nbsp;you.&nbsp;</>
                    )}
                    This will arrive within 7 days.
                    {mailingAddress && (
                      <>
                        <br />
                        <Button
                          variant='link'
                          onClick={() =>
                            handleClick(
                              'button_link_arrow',
                              'Update your address'
                            )
                          }
                          style={{
                            backgroundColor: 'transparent',
                            border: 'none',
                            outline: 'none',
                            color: '#2e5299',
                            fontWeight: '100',
                            minWidth: '0px',
                            padding: '0px',
                            margin_bottom: '5px',
                          }}
                        >
                          {' '}
                          Update your address
                        </Button>
                      </>
                    )}
                  </p>
                </IconLabelWrapper>
                <IconLabelWrapper>
                  <div className='iconWrapper'>
                    <IconNotificationSuccess />
                  </div>
                  <p>Can be used for legal proceedings</p>
                </IconLabelWrapper>
                <Spacer mt={-0.5} />
              </>
            )}
            {isCertified ? (
              <Heading level={3}>And an uncertified driving record</Heading>
            ) : (
              <Heading level={3}>An uncertified driving record</Heading>
            )}
            <Spacer mb={2}>
              <Spacer mb={1.5} mt={-0.5}>
                {documentState === 'error' && (
                  <Notification
                    variant='error'
                    title={
                      <span
                        style={{
                          fontWeight: 400,
                          marginBottom: '0.25rem',
                          display: 'block',
                        }}
                      >
                        We’re unable to retrieve your uncertified driving
                        record. Please try again. Need help? Call us on{' '}
                        <DisableTelOnTabletDesktop phoneNumber='13 77 88' /> or
                        visit a{' '}
                        <a
                          href='https://www.service.nsw.gov.au/service-centre'
                          style={{ color: `${colors.blue}` }}
                          target='_blank'
                          rel='noopener noreferrer'
                        >
                          service centre
                        </a>
                        .
                      </span>
                    }
                    role='alert'
                    className='notice-wrapper'
                  />
                )}
                <IconLabelWrapper>
                  <div className='iconWrapper'>
                    <IconDownload />
                  </div>
                  <Button
                    variant='link'
                    onClick={() =>
                      onDownloadUdrButton(
                        'Keep a receipt for your records',
                        'Download uncertified driving record'
                      )
                    }
                    style={{
                      backgroundColor: 'transparent',
                      border: 'none',
                      outline: 'none',
                      color: '#2e5299',
                      fontWeight: '100',
                      minWidth: '0px',
                      padding: '0px',
                      margin_bottom: '5px',
                    }}
                  >
                    {documentState === 'loading' ? (
                      <Svg.Spinner color='#646974' />
                    ) : (
                      'Download uncertified driving record'
                    )}
                  </Button>
                </IconLabelWrapper>
                <Spacer mb={1} />
                {!cdrWarning && (
                  <IconLabelWrapper>
                    <div className='iconWrapper'>
                      <AtEmailIconSVGImage />
                    </div>
                    <p>
                      This has been emailed to
                      <br />
                      <strong>{emailAddress}</strong>
                      <br />
                      <Link
                        className='link'
                        style={{
                          textDecoration: 'underline',
                          cursor: 'pointer',
                          marginLeft: 0,
                        }}
                        activeClass='active'
                        to='emailform'
                        spy={true}
                        smooth={true}
                        offset={0}
                        duration={800}
                        onClick={() =>
                          handleClick(
                            'button_link_small',
                            'Send to another email'
                          )
                        }
                      >
                        Send to another email
                      </Link>
                    </p>
                  </IconLabelWrapper>
                )}
                <IconLabelWrapper>
                  <div className='iconWrapper'>
                    <IconNotificationError />
                  </div>
                  <p>Cannot be used for legal proceedings</p>
                </IconLabelWrapper>
                <IconLabelWrapper>
                  <div className='iconWrapper'>
                    <IconCalendar />
                  </div>
                  <p>
                    Access until{' '}
                    {getDrivingRecordAccessUntilDate(transactionDate)}
                  </p>
                </IconLabelWrapper>
              </Spacer>
              <hr />
            </Spacer>

            <Spacer mt={-1} />
            <Heading level={2}>Payment Summary</Heading>
            <Spacer mt={1} />
            <p>
              Receipt number: {receiptNumber}
              <br />
              <Spacer mb={0.5} />
              Purchase date: {transactionDate}
              <br />
              <Spacer mb={0.5} />
              <b>Total amount paid: {helpers.AUDdecimal(totalAmountPaid)}</b>
            </p>
            <Spacer mt={2} mb={0} data-testid='downloadReceipt'>
              <h3 style={{ fontSize: '1.35rem' }}>
                Download or share your receipt
              </h3>
              <IconLabelWrapper style={{ marginBottom: '0rem' }}>
                <div className='iconWrapper'>
                  <IconPrint />
                </div>
                <a
                  href={receiptUrl}
                  title={receiptFilename}
                  target='_blank'
                  rel='noopener noreferrer'
                  onClick={() => handleClick('button_link_small', 'Print')}
                >
                  Print
                </a>
              </IconLabelWrapper>
              <IconLabelWrapper>
                <div className='iconWrapper'>
                  <IconDownload />
                </div>
                <a
                  download={receiptFilename}
                  href={receiptUrl}
                  title='Download your receipt'
                  onClick={() =>
                    handleClick('button_link_small', 'Download your receipt')
                  }
                >
                  Download your receipt
                </a>
              </IconLabelWrapper>
            </Spacer>
            <Spacer mb={2}>
              <EmailForm uuid={uuid} emailInputRef={emailInputRef} />
            </Spacer>
            <hr />
            <>
              <ButtonStyle>
                <Spacer mt={2}>
                  <Button
                    data-testid='button-get-drivingRecord'
                    className='button-get-drivingRecord'
                    onClick={() => {
                      history.push('/odr-cdr/drivingRecord');
                    }}
                  >
                    <strong>View your driving record</strong>
                  </Button>
                </Spacer>
                <Spacer mt={2} mb={2}>
                  <Button
                    className='button_survey'
                    variant='secondary'
                    onClick={() => {
                      window.location.href =
                        'https://forms.office.com/r/KX9K42cDcs';
                    }}
                    data-testid='button_survey'
                    external
                  >
                    <strong> Help us improve</strong>
                  </Button>
                </Spacer>
              </ButtonStyle>
              <Spacer mb={2.5}>
                <Quickfeed
                  sourceApplication='driving-record'
                  label='How was your driving record purchase experience?'
                  environment={process.env.REACT_APP_TUTD}
                />
              </Spacer>
            </>
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
  return (
    <>
      {(status === '' || status === 'error') && <ErrorPage />}
      {status === 'success' && purchaseSuccessContent}
      <ComponentLoader
        active={status === 'loading'}
        fullPage={true}
        label='One moment please...'
      />
    </>
  );
};

export default PurchasedSuccess;

type confirmationHistoryState = undefined | Record<string, any>;
