import { odrRequestConstants } from 'redux/actions';
import { odrRequestErrorMessages } from  'utils/errorMessages';

export const initialState = {
  status: '',
  emailSentTo: '',
  error: {
    message: '',
    severity: '',
    identifier: '',
    state: '',
    errorMessages: {
      header: '',
      message: '',
    },
  },
};

export const odrRequest = (state = initialState, payload: any) => {
  const requestRponse = payload.data;

  switch (payload.type) {
    case odrRequestConstants.REQUEST_ODR_REQUEST:
      return {
        ...state,
        status: 'loading',
      }
    case odrRequestConstants.SUCCESS_ODR_REQUEST:
      return {
        ...state,
        error: {
          state: requestRponse.error !== '' && 'error',
          message: requestRponse.error,
        },
        status: 'success',
      }
    case odrRequestConstants.FAILTURE_ODR_REQUEST:
      return {
        ...state,
        error: {
          state: 'error',
          messages: {
            header: odrRequestErrorMessages.header,
            message: (odrRequestErrorMessages.message),
          },
        },
        status: 'error',
      }
    case odrRequestConstants.SET_ODR_REQUEST:
      return {
        ...state,
        ...payload.data,
      }
  default:
    return state;
  }
};
