import React from 'react';
import { Modal } from '@snsw/react-component-library';

export const ModalTermsConditions = ({
  show = false,
  onClose = () => {},
}) => (
  <>
    {show && (
      <Modal
        isScrollable={true}
        title='Terms & Conditions'
        buttons={[
          {
            text: 'Close',
            onClick: onClose,
          }
        ]}
        onClose={onClose}
      >
        <div className='modalBody modalTerms'>
          <ol>
            <li>
              <strong>These Terms Apply:</strong>
              &nbsp;These Terms and Conditions, together with the&nbsp;
              <a
                className='h-hyperlink'
                href='https://www.service.nsw.gov.au/terms-use'
                target='_blank'
                rel='noopener noreferrer'
              >
                Terms of use
              </a>,
              &nbsp;
              <a
                className='h-hyperlink'
                href='https://www.service.nsw.gov.au/copyright-and-disclaimer'
                target='_blank'
                rel='noopener noreferrer'
              >
                Copyright statement
              </a>,
              &nbsp;
              <a
                className='h-hyperlink'
                href='https://www.service.nsw.gov.au/privacy'
                target='_blank'
                rel='noopener noreferrer'
              >
                Privacy statement
              </a>
              &nbsp;published on the Service NSW website ("Terms"), set out the conditions that apply
              to your use of the records enquiry service ("Service"). By using the Service you
              agree to comply with these Terms together with all relevant guidelines, instructions
              and procedures (“Instructions”) posted on this site. Nothing in these Terms limits
              or qualifies your rights or obligations under any legislation, including legislation
              relating to motor vehicles, traffic, roads or road transport.
            </li>
            <li>
              <strong>Changing These Terms:</strong>
              &nbsp;Transport for NSW and Service NSW may change these Terms, and their Instructions
              from time to time by republishing them online. You agree that by doing this,
              you have been provided with sufficient notice of the variation.
              You should read the Terms and Instructions each time before you use the Service
              in case they have changed. If you do not agree to any change,
              you should not continue to use the Service.
            </li>
            <li>
            <strong>Use, Suitability and Reliability:</strong>
              &nbsp;This service is provided so that you can access your own NSW driver licence information
              for personal use. You must not use it for commercial purposes or to provide services to
              others. If you require information for legal proceedings or other evidence you must contact
              Transport for NSW or Service NSW for a certified copy. This information may not be up to date,
              complete or accurate as at the date on which you access it.
            </li>
            <li>
              <strong>Security of Information:</strong>
              &nbsp;You are responsible for taking reasonable care in how you access the Service,
              including using appropriate firewalls and anti-virus software. No data transmission over the
              Internet can be guaranteed as totally secure and communications to this Service may be intercepted
              or altered in transit. Although Transport for NSW and Service NSW strive to protect such information,
              they do not represent or warrant and cannot ensure the security of any information which is
              transmitted when you use the Service. When using this Service you transmit and receive information
              at your own risk and Transport for NSW and Service NSW bear no liability.
            </li>
            <li>
              <strong>Unavailability of Service:</strong>
              &nbsp;You acknowledge and agree that from time to time the Service may be unavailable.
            </li>
            <li>
              <strong>Age:</strong>
              &nbsp;You warrant that you are aged 18 or over. If you are under 18, you may ask us to provide
              the Service only with the involvement of a parent or guardian.
            </li>
            <li>
              <strong>Jurisdiction:</strong>
              &nbsp;These Terms, and your application to use the Service are governed by the laws of
              New South Wales, Australia, and you irrevocably submit to the jurisdiction
              of the courts of New South Wales.
            </li>
          </ol>
        </div>
      </Modal>
    )}
  </>
);
