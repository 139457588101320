import React from 'react';
import { H2, Notification, Button } from '@snsw/react-component-library';
import { Spacer } from '@rmstransactions/components';

const ErrorNotice = ({ header = '', message = '', hasQuit = false }) => {
  let hasTitle = false;
  if (header !== '') hasTitle = true;
  return (
    <Spacer mb={1.5}>
      {hasTitle && <H2 dangerouslySetInnerHTML={{ __html: header }} />}
      <Spacer mb={1.5}>
        <Notification
          variant="error"
          role="alert"
          className="notice-wrapper"
          title={
            <p
              className="h-notice-title"
              dangerouslySetInnerHTML={{ __html: message }}
            />
          }
        />
      </Spacer>
      {hasQuit ? (
        <Button
          className="btn__link"
          theme="secondary"
          href="https://www.service.nsw.gov.au/transaction/check-your-demerit-points"
          data-testid="error-notice-btn"
        >
          Quit
        </Button>
      ) : null}
    </Spacer>
  );
};

export default ErrorNotice;
