import { isMobile } from "react-device-detect";

function getMobileOrDesktopErrors(): string {
  if (isMobile) {
    return `
    We need to check a few details with you before we proceed.
    Please call us on <a href="tel:137788">13 77 88</a> or visit a
    <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `
  We need to check a few details with you before we proceed.
  Please call us on 13 77 88 or visit a
  <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`

}

function getMobileOrDesktopEnforcementMessage(): string {
  if (isMobile) {
    return `We need to check a few details with you before we can proceed. For more information,
    please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `We need to check a few details with you before we can proceed. For more information, please call us on 13 77 88
   or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`

}

function getMobileOrDesktopInactiveLicence(): string {

  if (isMobile) {
    return `Unfortunately we can't show a breakdown for each class. for more details,
    please call <a href='tel:137788'>13 77 88</a> or visit a
    <a href='https://www.service.nsw.gov.au/contact-us' rel='noreferrer'>Service Centre</a>.
    </br>Note: Your certified and online driving records will always show a complete hisotry
    of your demerit points.`
  }
  // desktop
  return `Unfortunately we can't show a breakdown for each class. for more details,
    please call 13 77 88 or visit a
    <a href='https://www.service.nsw.gov.au/contact-us' rel='noreferrer'>Service Centre</a>.
    </br>Note: Your certified and online driving records will always show a complete hisotry
    of your demerit points.`
}

function getMobileOrDesktopOdr(): string {
  if (isMobile) {
    return `Please try again, call us on <a href='tel:137788'>13 77 88</a> or visit a
    <a href='https://www.service.nsw.gov.au/contact-us' rel='noreferrer'>Service Centre</a>.`
  }
  // desktop
  return `Please try again, call us on 13 77 88 or visit a
  <a href='https://www.service.nsw.gov.au/contact-us' rel='noreferrer'>Service Centre</a>.`
}

function getMobileOrDesktopSystemFailureError(): string {
  if (isMobile) {
    return `Our system is temporarily unavailable, we apologise for any inconvenience. You can try again later,
    call us on <a href="tel:137788">13 77 88</a> or visit a
    <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `Our system is temporarily unavailable, we apologise for any inconvenience. You can try again later,
  call us on 13 77 88 or visit a
  <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`

}

function getMobileOrDesktopGBPErrorMessage(): string {
  if (isMobile) {
    return `<h2>You are serving a good behaviour period</h2><br>
    Please call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `<h2>You are serving a good behaviour period</h2>
  Please call us on 13 77 88 or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`

}

function getMobileOrDesktopInvalidLicenceMessage(): string {
  if (isMobile) {
    return `There was a technical error and your driver licence was not found. We apologise for any inconvenience.
    You can try again later, call us on <a href="tel:137788">13 77 88</a> or visit a
    <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `There was a technical error and your driver licence was not found. We apologise for any inconvenience.
  You can try again later, call us on 13 77 88 or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
}

function getMobileOrDesktopNotIndividualMessage(): string {
  if (isMobile) {
    return `It looks like you're a business customer. To change your contact details, please visit a
    <a href="https://www.service.nsw.gov.au/service-centre">service centre</a> or call us on <a href="tel:137788">13 77 88</a>.`
  }
  // desktop
  return `It looks like you're a business customer. To change your contact details, please visit a
  <a href="https://www.service.nsw.gov.au/service-centre">service centre</a> or call us on 13 77 88.`
}

function getMobileOrDesktopNotFoundMessage(): string {
  if (isMobile) {
    return `Your details were not found. We apologise for the inconvenience. Please call us on
    <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `Your details were not found. We apologise for the inconvenience. Please call us on 13 77 88
  or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
}

function getMobileOrDesktopInvalidInputMessage(): string {
  if (isMobile) {
    return `There was a technical error, we apologise for any inconvenience. You can try again later,
    call us on <a href="tel:137788">13 77 88</a> or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `There was a technical error, we apologise for any inconvenience. You can try again later,
  call us on 13 77 88 or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
}

function getMobileOrDesktopExpiredLicenceMessage(): string {
  if (isMobile) {
    return `<h2>Your licence has expired</h2><br>
    You may be eligible to <a href="https://www.service.nsw.gov.au/transaction/renew-or-upgrade-nsw-driver-licence">renew your licence online</a>.
    For more information, please call us on <a href="tel:137788">13 77 88</a>
    or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `<h2>Your licence has expired</h2>
  You may be eligible to <a href="https://www.service.nsw.gov.au/transaction/renew-or-upgrade-nsw-driver-licence">renew your licence online</a>.
  For more information, please call us on 13 77 88 or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
}

function getMobileOrDesktopLicenceNotFoundMessage(): string {
  if (isMobile) {
    return `Your driver licence was not found. We apologise for the inconvenience. Please call us on <a href="tel:137788">13 77 88</a>
    or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `Your driver licence was not found. We apologise for the inconvenience. Please call us on 13 77 88
  or visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
}

function getMobileOrDesktopUnableGeneratePdfMessage(): string {
  if (isMobile) {
    return `Please try to download again, call us on <a href="tel:137788">13 77 88</a> or
    visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `Please try to download again, call us on 13 77 88 or
  visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
}

function getMobileOrDesktopODRNotAvailableMessage(): string {
  if (isMobile) {
    return `Please try again, call us on <a href="tel:137788">13 77 88</a> or
    visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `Please try again, call us on 13 77 88 or
  visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
}

function getLicenceInactiveMessage(): string {
  if (isMobile) {
    return `<h2>Your NSW Driver Licence is not active</h2><br>
    For more information, please call us on <a href="tel:137788">13 77 88</a> or
    visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
  }
  // desktop
  return `<h2>Your NSW Driver Licence is not active</h2><br>
  For more information, please call us on 13 77 88 or
  visit a <a href="https://www.service.nsw.gov.au/service-centre">service centre</a>.`
}

export const drivingRecordErrorMessages: Array<ErrorMessage> = [
  {
    code: 'CUSTOMER_HAS_CAMS',
    message: getMobileOrDesktopErrors(),
    header: `Cannot show driving record`
  },
  {
    code: 'INVALID_LICENCE_ID',
    message: getMobileOrDesktopInvalidLicenceMessage(),
    header: `Cannot show driving record`
  },
  {
    code: 'CUSTOMER_NOT_INDIVIDUAL',
    message: getMobileOrDesktopNotIndividualMessage(),
    header: `Cannot show driving record`
  },
  {
    code: 'CUSTOMER_NOT_FOUND',
    message: getMobileOrDesktopNotFoundMessage(),
    header: `Cannot show driving record`
  },
  {
    code: 'INVALID_INPUT',
    message: getMobileOrDesktopInvalidInputMessage(),
    header: `Cannot show driving record`
  },
  {
    code: 'UNABLE_GENERATE_PDF',
    message: getMobileOrDesktopUnableGeneratePdfMessage(),
    header: `Sorry, we were unable to generate your online driving record`
  },
  {
    code: 'ODR_NOT_AVAILABLE',
    message: getMobileOrDesktopODRNotAvailableMessage(),
    header: `Sorry, we're unable to provide you with a online driving record`
  }
]

export const reportErrorMessages: Array<ErrorMessage> = [
  {
    code: 'CUSTOMER_HAS_CAMS',
    message: getMobileOrDesktopErrors(),
    header: `Cannot show summary`
  },
  {
    code: 'CUSTOMER_HAS_ENFORCEMENT',
    message: getMobileOrDesktopEnforcementMessage(),
    header: `Cannot show summary`
  },
  {
    code: 'CUSTOMER_HAS_GBP',
    message: getMobileOrDesktopGBPErrorMessage(),
    header: `Cannot show summary`
  },
  {
    code: 'CUSTOMER_NOT_FOUND',
    message: getMobileOrDesktopNotFoundMessage(),
    header: `Cannot show summary`
  },
  {
    code: 'INVALID_INPUT',
    message: getMobileOrDesktopInvalidInputMessage(),
    header: `Cannot show summary`
  },
  {
    code: 'CUSTOMER_HAS_EXPIRED_LIC',
    message: getMobileOrDesktopExpiredLicenceMessage(),
    header: `Cannot show summary`
  },
  {
    code: 'DL_NOT_FOUND',
    message: getMobileOrDesktopLicenceNotFoundMessage(),
    header: `Cannot show summary`
  },
  {
    code: 'CUSTOMER_LIC_CLASS_NOT_ACTIVE',
    message: getLicenceInactiveMessage(),
    header: `Cannot show summary`
  }
]

export const demeritsErrorMessages: Array<ErrorMessage> = [
  {
    code: 'SYSTEM_FAILURE',
    message: getMobileOrDesktopSystemFailureError(),
    header: '<h1>We\'re sorry about this...</h1>'
  }
]

export interface ErrorMessage {
  code: string;
  message: string;
  header?: string;
}

export const errorReport = {
  GENERAL_ERROR: {
    header: `Cannot show summary`,
    message: `
      Our system is temporarily unavailable, we apologise for any inconvenience. You can try again later,
      <a href='https://www.service.nsw.gov.au/contact-us' rel='noreferrer'>contact us</a> or visit a
      <a href='https://www.service.nsw.gov.au/service-centre' rel='noreferrer'>Service Centre</a>.
    `,
  }
};

export const errorRecord = {
  INELIGIBLE: {
    header: ``,
    message: getMobileOrDesktopErrors(),
  },
};

export const infoMessages = {
  CUSTOMER_LIC_CLASS_NOT_ACTIVE: {
    header: 'You have an inactive licence class which has demerit points towards your total',
    message: getMobileOrDesktopInactiveLicence(),
  }
};

export const odrRequestErrorMessages = {
  header: 'Sorry, we were unable to send your online driving record',
  message: getMobileOrDesktopOdr(),
};


