// constants
export const globalStateConstants = {
  SET_TRANSACTION_TYPE: 'TRANSACTION_TYPE',
};

export type transactionType = 'ODRCDR' | 'DPC';

export function setTransactionType(payload: string) {
  return {
    type: globalStateConstants.SET_TRANSACTION_TYPE,
    payload,
  };
};