import React, { useEffect } from 'react';
import ReactDOM from 'react-dom';
import store from 'redux/store';
import { Provider } from 'react-redux';
import { Route, Router, Switch } from 'react-router-dom';
import { theme, GlobalStyle } from '@snsw/react-component-library';
import { history } from './utils/history';
import { ThemeProvider } from 'styled-components';
import { ThemeLocal } from './components/Styled';
import { forceLogout } from 'services/DemeritPointsService';
import {
  TermsCondition,
  Summary,
  NotFound,
  LoginPage,
  DrivingRecord,
  PurchasedSuccess,
  PurchasedRedirect,
} from './pages';
import { ErrorPage } from 'components';
import { goToLogin, PATH_DRIVING_RECORD, TransactionType } from 'utils/helpers';
import * as serviceWorker from './serviceWorker';
import GlobalStateProvider from './providers/globalState';
import {
  RMSGlobalStyle,
  MyAccountNav,
  MyAccountNavConfig,
  helpers,
  LoggedOutErrorPage,
} from '@rmstransactions/components';
import { GlobalNav } from '@snsw/react-global-nav';

function Root() {
  const pathArray = window.location.pathname.split('/');
  const pathParams = pathArray[2];
  const transactionType =
    pathParams === PATH_DRIVING_RECORD
      ? TransactionType.ODRCDR
      : TransactionType.DPC;

  useEffect(() => {
    let timerId: any = sessionTimer;
    const resetTimer = () => {
      clearTimeout(timerId);
      timerId = setTimeout(() => {
        forceLogout().finally(() => {
          goToLogin(transactionType);
        });
      }, helpers.sessionTime);
    };

    document.body.addEventListener('click', resetTimer);

    return () => {
      clearTimeout(timerId);
      window.removeEventListener('click', resetTimer);
    };
  }, []); // eslint-disable-line

  const sessionTimer = setTimeout(() => {
    forceLogout().finally(() => {
      goToLogin(transactionType);
    });
  }, helpers.sessionTime);

  MyAccountNavConfig.setupGlobalNavUri(process.env.REACT_APP_GLOBAL_NAV_ENV);
  MyAccountNavConfig.setLoggedInUser();

  const html = (
    <>
      <RMSGlobalStyle />
      <GlobalNav accountNavLinks={<MyAccountNav />}>
        <GlobalStateProvider>
          <ThemeProvider theme={theme}>
            <GlobalStyle />
            <ThemeLocal>
              <Provider store={store}>
                <Router history={history}>
                  <Switch>
                    <Route exact path='/' component={TermsCondition} />
                    <Route exact path='/odr-cdr' component={TermsCondition} />
                    <Route exact path='/error' component={ErrorPage} />
                    <Route
                      path='/view-receipt/:uuid'
                      component={PurchasedSuccess}
                    />
                    <Route
                      exact
                      path='/:transactionTypeParm'
                      component={TermsCondition}
                    />
                    <Route path='/linked' component={TermsCondition} />
                    <Route
                      exact
                      path='/odr-cdr/linked'
                      component={TermsCondition}
                    />
                    <Route path='/login' component={LoginPage} />
                    <Route
                      path='/demerit-enquiry/summary'
                      component={Summary}
                    />
                    <Route
                      path='/odr-cdr/drivingRecord'
                      component={DrivingRecord}
                    />
                    <Route path='/odr-cdr/success/:uuid'>
                      <PurchasedRedirect redirectURL='/odr-cdr/success' />
                    </Route>
                    <Route
                      path='/odr-cdr/success'
                      component={PurchasedSuccess}
                    />
                    <Route
                      path='/error/logged-out'
                      component={LoggedOutErrorPage}
                    />
                    <Route path='*' component={NotFound} />
                  </Switch>
                </Router>
              </Provider>
            </ThemeLocal>
          </ThemeProvider>
        </GlobalStateProvider>
      </GlobalNav>
    </>
  );

  return html;
}

if (process.env.REACT_APP_MOCK_TEST) {
  const { worker } = require('./utils/mockData/browser.ts');
  worker.start();
}

ReactDOM.render(<Root />, document.getElementById('root'));
serviceWorker.unregister();
