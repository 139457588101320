export const Config: AppConfig = {
  apiHost: process.env.REACT_APP_API_HOST || '',
  apiProcessPaymentPath: '/auth/processPayment',
  apiGetDemeritReportPath: '/auth/rms-demeritPointsCheck',
  drivingRecordHost: process.env.REACT_APP_DRIVING_RECORD_HOST || '',
  apiGatewayKey: process.env.REACT_APP_GATEWAY_PROXY_API_KEY || '',
};

export const DRUPAL_DPC_URL =
  'https://www.service.nsw.gov.au/transaction/check-your-demerit-points';
export const DRUPAL_UDRCDR_URL =
  'https://www.service.nsw.gov.au/transaction/request-driving-record';

export interface AppConfig {
  apiHost: string;
  apiProcessPaymentPath: string;
  drivingRecordHost: string;
  apiGetDemeritReportPath: string;
  apiGatewayKey: string;
}
