// constants
export const drivingRecordCardSelectTypeConstants = {
    SET_DRIVING_RECORD_CARD_SELECTION_TYPE: 'SET_DRIVING_RECORD_CARD_SELECTION_TYPE',
};
  
export function setDrivingRecordCardSelectType(payload: string) {
    return {
        type: drivingRecordCardSelectTypeConstants.SET_DRIVING_RECORD_CARD_SELECTION_TYPE,
        payload,
    };
};