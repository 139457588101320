import { validateOdrCdrConstants } from 'redux/actions';
import { IMessageType } from 'interfaces';
import { errorResolver } from 'utils';
import {
  demeritsErrorMessages,
  drivingRecordErrorMessages,
} from 'utils/errorMessages';

export const initialState = {
  status: '',
  checkLoginStatus: '',
  generalErrorMessage: '',
  demeritPointsCdr: {},
  demeritPointsOdr: {},
  error: {
    odr: [{
      message: '',
      severity: '',
      identifier: '',
      state: '',
      errorMessages: {
        header: '',
        message: '',
      },
    }],
    cdr: [{
      message: '',
      severity: '',
      identifier: '',
      state: '',
      errorMessages: {
        header: '',
        message: '',
      },
    }],
  },
};

export const validateOdrCdrDrivingRecord = (state = initialState, payload: any) => {
  switch (payload.type) {
    case validateOdrCdrConstants.REQUEST_VALIDATE_ODR_CDR:
      return {
        ...state,
        status: validateOdrCdrConstants.LOADING,
      }
    case validateOdrCdrConstants.SUCCESS_VALIDATE_ODR_CDR:
      const  demeritsOdr = payload.data.demeritPointsOdr,
        demeritsCdr = payload.data.demeritPointsCdr;

      const errorState = {
        odr: demeritsOdr.messages?.map((item: IMessageType) => ({
          severity: item.severity,
          identifier: item.code,
          message: item.message,
          state: errorResolver(item.code, validateOdrCdrConstants.ODR),
          errorMessages: drivingRecordErrorMessages.filter(error => error.code === item.code)[0],
        })) || { ...state.error.odr },
        cdr: demeritsCdr.messages?.map((item: IMessageType) => ({
          severity: item.severity,
          identifier: item.code,
          message: item.message,
          state: errorResolver(item.code, validateOdrCdrConstants.CDR),
          errorMessages: drivingRecordErrorMessages.filter(error => error.code === item.code)[0],
        })) || { ...state.error.cdr },
      };

      const checkOverallError = (errorCollection: any) => {
        const
          firstErrorCode = errorCollection.odr[0].identifier,
          secondErrorCode = errorCollection.cdr[0].identifier,
          errors = [firstErrorCode, secondErrorCode ];

        const result = errors.filter((error, i) => (error !== validateOdrCdrConstants.SUCCESS && error === errors[i]));
        const getUnique = result.filter((v, i, a) => a.indexOf(v) === i);
        return (result.length === 3 && getUnique.length) === 1 ? true : false;
      };

      const setGeneralErrorMessage = (errorCollection): string => {
        if (errorCollection.cdr[0].state === validateOdrCdrConstants.GENERAL_ERROR) {
          return errorCollection.cdr[0].errorMessages.message;
        }
        if (errorCollection.odr[0].state === validateOdrCdrConstants.GENERAL_ERROR) {
          return errorCollection.odr[0].errorMessages.message;
        }
        //System ErrorPage is not invoked in this scenario and hence returning success
        return validateOdrCdrConstants.SUCCESS;
      };
      return {
        ...state,
        status: validateOdrCdrConstants.SUCCESS,
        demeritPointsCdr: demeritsCdr,
        demeritPointsOdr: demeritsOdr,
        error: {
          hasMatchedError: checkOverallError(errorState),
          ...errorState,
        },
        generalErrorMessage: setGeneralErrorMessage(errorState),
      }
    case validateOdrCdrConstants.FAILURE_VALIDATE_ODR_CDR:
      return {
        ...state,
        status: validateOdrCdrConstants.ERROR,
        generalErrorMessage: demeritsErrorMessages.filter(item => item.code === validateOdrCdrConstants.SYSTEM_FAILURE)[0].message,
      }
    case validateOdrCdrConstants.SET_LOGIN_STATUS:
      return {
        ...state,
        checkLoginStatus: payload.data.checkLoginStatus,
        generalErrorMessage: payload.data.statusMessage,
      }
    default:
      return state;
  }
};
