import { IErrorMessageType } from 'interfaces';

export const initialState = {
  code: '',
  category: '',
  severity: '',
  message: '',
  messageTitle: '',
  messageDescription: ''
};

export const errorMessages = (
  state = initialState,
  payload: IErrorMessageType
) => {
  if (payload.type === "errorMessages")
    return payload.data
  else
    return initialState
};
