import { termsAndConditionState } from 'redux/actions';

export const initialState = {
  hasAcceptedTermsAndCond: false,
};


const checkCookie = (): boolean => {
   return (!document.cookie.split(';').some(r => r.includes('tAndC=true'))) ? false : true ; 
}

export const termsAndConditionReducer = (state = initialState, action: any) => {

  switch (action.type) {
    case termsAndConditionState.SET_TERMS_AND_COND_STATE:
      return {
        ...state,
        hasAcceptedTermsAndCond: action.payload,
      }
    default:
      return {
        ...state,
        hasAcceptedTermsAndCond: checkCookie()
      }
  }
};
