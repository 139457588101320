import { validateOdrCdr } from 'services/OdrCdrService';
import { UserToken, AnalyticsService } from '@rmstransactions/components';
import { history } from "../../utils";
import { Constants } from 'utils/helpers';

// constants
export const validateOdrCdrConstants = {
  REQUEST_VALIDATE_ODR_CDR: 'REQUEST_VALIDATE_ODR_CDR',
  SUCCESS_VALIDATE_ODR_CDR: 'SUCCESS_VALIDATE_ODR_CDR',
  FAILURE_VALIDATE_ODR_CDR: 'FAILURE_VALIDATE_ODR_CDR',
  SET_LOGIN_STATUS: 'SET_LOGIN_STATUS',
  ODR: 'odr',
  CDR: 'cdr',
  SUCCESS: 'success',
  SYSTEM_FAILURE: 'SYSTEM_FAILURE',
  LOADING: 'loading',
  GENERAL_ERROR: 'GENERAL_ERROR',
  ERROR: 'error',
};

// actions
export function validateOdrCdrDrivingRecord() {
  return (dispatch: any) => {
    if (process.env.REACT_APP_MOCK_TEST) {
      try {
        const userData = require(`./../../utils/mockData/odrcdr/validateOdrCdrSuccess`);
        const customerDetails = userData.default;
        const headers = [];
        headers['x-token'] = 'ytutuutj';
        dispatch(success(customerDetails, headers));
      } catch (e) {
        console.assert(true, validateOdrCdr());
        dispatch(failure(e));
      }
      return;
    }

    dispatch(request());
    validateOdrCdr()
      .then((response) => {
        UserToken.setValue(response.headers['x-token']);
        if (response?.data?.demeritPointsOdr?.messages[0].severity === 'SUCCESS' && response?.data?.demeritPointsCdr?.messages[0].severity === 'SUCCESS' ) {
          dispatch(success(response.data, response.headers));
          return;
        } else {
          if (response?.data?.demeritPointsOdr?.messages[0].severity !== 'SUCCESS')
            dispatch(failure(response.data?.demeritPointsOdr?.messages[0]));
          else
            dispatch(failure(response.data?.demeritPointsCdr?.messages[0]));
          AnalyticsService.ErrorReportUpgrade(
              Constants.SYSTEMS_ERROR,
              Constants.TECHNICAL_ERROR,
              response.data?.demeritPointsOdr?.messages[0]+ response.data?.demeritPointsCdr?.messages[0],
            new Date(),
            Constants.ERROR_POSITION_BODY
            );
           history.push('/error')
        }
      })
      .catch((error) => {
        dispatch(failure(error));
        AnalyticsService.ErrorReport(
          Constants.SYSTEMS_ERROR,
          Constants.TECHNICAL_ERROR,
          error,
          new Date(),
        );
      });
  };

  function request(data?: Object) {
    return { type: validateOdrCdrConstants.REQUEST_VALIDATE_ODR_CDR, data };
  }
  function success(data: Object, headers: Object) {
    return {
      type: validateOdrCdrConstants.SUCCESS_VALIDATE_ODR_CDR,
      data,
      headers,
    };
  }
  function failure(data: Object) {
    return { type: "errorMessages", data };
  }
}
