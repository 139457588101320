import React from 'react';
import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';
import { ReactComponent as UncertifiedImageSVG } from 'assets/uncertified-frameless.svg';
import { ReactComponent as CertifiedImageSVG } from 'assets/certified-frameless.svg';
import { setDrivingRecordCardSelectType } from 'redux/actions';
import { useDispatch } from 'react-redux';
const styleProperties = {
  highRes: '22px',
  lowRes: '18px',
  highLineHeight: '16px',
  lowLineHeight: '8px',
};

export const CertifiedImage = styled(CertifiedImageSVG)`
  margin-top: 8px;
  margin-left: 24px;
  width: 148px;
  height: 64px;
  align: center;

  display: flex;
  justify-content: center;
  align-items: center;
  fill: blue;
  display: block;

  ${Breakpoint('sm')`
          margin-top: 8px;
          margin-left: 85px;
          viewBox: 0 0 158 64
          transform: scale(1.2)
        `}
`;
export const UncertifiedImage = styled(UncertifiedImageSVG)`
  margin-top: 8px;
  margin-left: 68px;

  display: flex;
  justify-content: center;
  align-items: center;
  width: 44px;
  height: 63px;
  fill: blue;
  display: block;
  align: center;

  ${Breakpoint('sm')`
          width: 89.3px;
          height: 61.22px;
          margin-left: 96px;
          transform: translateY(1%)
        `}
`;
export const CardContainer = styled.article`
  display: flex;
  align-items: center;
`;
export const Card = styled.div`
        height: 204px;
        width: 170px;
        
        ${Breakpoint('sm')`
         height: 202px;
         width: 280px;
          
          position: relative;
            border-radius: 0.5rem;
            box-shadow: 2px 4px 9px -2px rgba(19, 36, 69, 0.2);
            background: rgb(255, 255, 255);
            margin-right: 1.25rem
            border-top: 6px solid #2e5299;
            cursor:pointer;
            &[data-card-status="selected"] {
              border-right: 3px solid #002664;
              border-left: 3px solid #002664;
              border-bottom: 3px solid #002664;
              box-shadow: 0px 2px 4px -1px rgba(19, 36, 69, 0.1),0px 4px 5px 0px rgba(19, 36, 69, 0.05), 0px 1px 10px 0px rgba(19, 36, 69, 0.05);
            }
            &[data-card-status="error"] {
              border: 3px solid #cc2c1c;
            }
            &:hover {
                  box-shadow: 0px 5px 5px -3px rgba(19, 36, 69, 0.1),
                      0px 8px 10px 1px rgba(19, 36, 69, 0.05),
                      0px 3px 14px 2px rgba(19, 36, 69, 0.05);
              }
            &:focus {
                  box-shadow: 0px 5px 5px -3px rgba(19, 36, 69, 0.1),
                      0px 8px 10px 1px rgba(19, 36, 69, 0.05),
                      0px 3px 14px 2px rgba(19, 36, 69, 0.05);
                  border: 3px solid #002664;
      
            }
          }
      `}
   
        position: relative;
        border-radius: 0.5rem;
        box-shadow: 2px 4px 9px -2px rgba(19, 36, 69, 0.2);
        background: rgb(255, 255, 255);
        margin-right: 1.25rem
        border-top: 6px solid #2e5299;
        cursor:pointer;
        &[data-card-status="selected"] {
          border-right: 3px solid #2e5299;
          border-left: 3px solid #2e5299;
          border-bottom: 3px solid #2e5299;
          box-shadow: 0px 2px 4px -1px rgba(19, 36, 69, 0.1),0px 4px 5px 0px rgba(19, 36, 69, 0.05), 0px 1px 10px 0px rgba(19, 36, 69, 0.05);
        }
        &[data-card-status="error"] {
          border: 3px solid #cc2c1c;
        }
        &:hover {
              box-shadow: 0px 5px 5px -3px rgba(19, 36, 69, 0.1),
                  0px 8px 10px 1px rgba(19, 36, 69, 0.05),
                  0px 3px 14px 2px rgba(19, 36, 69, 0.05);
          }
        &:focus {
              box-shadow: 0px 5px 5px -3px rgba(19, 36, 69, 0.1),
                  0px 8px 10px 1px rgba(19, 36, 69, 0.05),
                  0px 3px 14px 2px rgba(19, 36, 69, 0.05);
              border: 3px solid #002664;
  
        }
      }
      `;
export const RadioInput = styled.input`
  height: 2rem;
  width: 2rem;
  margin-left: 24px;
  margin-top: 64px;
  cursor: pointer;
  outline: none;
  bottom: 0px;
  position: absolute;
  accent-color: #2e5299;
  border-radius: 50%;
  border: solid 50px #fff;
  display: none;
`;
export const Label = styled.label`
          content: "";
          &::after {
              content: "";
              position: absolute;
              ${Breakpoint('sm')`
                     margin-left: 24px;
              `}
              margin-left: 16px;
              margin-top: 64px;
              bottom: 0px;
              height: 30px;
              width: 30px;
              border: 2px solid #746964;
              border-radius: 50%;
          }
          &[data-card-status="selected"] {
              &::before {
                  content: "";
                  position: absolute;
                  margin-top: 66px;
                  margin-left: 14px;
                  ${Breakpoint('sm')`
                     margin-left: 22px;
                     transform: translateY(-154%) translateX(21%);
                  `}
                  transform: translateY(-188%) translateX(22%);
                  height: 24px;
                  width: 24px;
                  background-color: #2e5299;
                  border-radius: 50%;
              }
              &::after {
                  border: 2px solid #2e5299;
              }
      `;
export const RadioContainer = styled.div`
  height: 48px;
  position: relative;

  ${Breakpoint('sm')`
              height: 56px;
        `}
`;
export const CardText = styled.div`
          width: 170px
          color: #242934;
          font-size: ${styleProperties.lowRes}
          font-weight: bold;
          text-align: center;
          line-height: ${styleProperties.lowLineHeight};
          text-align: center;
          
          .block {
            display: block;
            height: ${styleProperties.lowLineHeight};
            
            ${Breakpoint('sm')`
              height: ${styleProperties.highLineHeight};
          `} 
          }
         .text {
            height: 26px;
            line-height: 26px;
         }
         
         &[data-card-status="selected"]{
             color: #2e5299
         }
          
        ${Breakpoint('sm')`
          width: 280px;
          height: 30px;
          color: #242934;
          font-size: 22px;
          font-weight: bold;
          text-align: center;
          line-height: 30px;
          #lineBreaker {
              display: none;
          }
        `}
      `;

const DrivingRecordCardSelection = ({
  costOdr,
  costCdr,
  udrCardStatus,
  setUdrCardStatus,
  cdrCardStatus,
  setCdrCardStatus,
}) => {
  const dispatch = useDispatch();

  const onCardSelection = (e: any) => {
    dispatch(setDrivingRecordCardSelectType(e.currentTarget.id));
    if (e.currentTarget.id === 'udrCard') {
      setUdrCardStatus('selected');
      setCdrCardStatus('default');
    } else if (e.currentTarget.id === 'cdrCard') {
      setUdrCardStatus('default');
      setCdrCardStatus('selected');
    }
  };

  return (
    <>
      <CardContainer>
        <Card
          id='udrCard'
          onClick={onCardSelection}
          data-card-status={udrCardStatus}
          data-testid='udrCard'
        >
          <RadioContainer>
            <Label data-card-status={udrCardStatus}></Label>
            <RadioInput
              type='radio'
              name='drivingCard'
              id='cardRadioUdr'
              checked={udrCardStatus === 'selected'}
            />
          </RadioContainer>
          <UncertifiedImage></UncertifiedImage>
          <CardText data-card-status={udrCardStatus}>
            <span className='block' />
            <span className='text'>Uncertified&nbsp;</span>
            <span id='lineBreaker' className='block' />
            <span className='text'>${costOdr}</span>
          </CardText>
        </Card>
        <Card
          id='cdrCard'
          onClick={onCardSelection}
          data-card-status={cdrCardStatus}
          data-testid='cdrCard'
        >
          <RadioContainer>
            <Label data-card-status={cdrCardStatus}></Label>
            <RadioInput
              type='radio'
              name='drivingCard'
              id='cardRadioCdr'
              checked={cdrCardStatus === 'selected'}
            />
          </RadioContainer>
          <CertifiedImage></CertifiedImage>
          <CardText data-card-status={cdrCardStatus}>
            <span className='block' />
            <span className='text'>Certified&nbsp;</span>
            <span id='lineBreaker' className='block' />
            <span className='text'>${costCdr}</span>
          </CardText>
        </Card>
      </CardContainer>
    </>
  );
};

export default DrivingRecordCardSelection;
