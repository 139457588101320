// constants
export const termsAndConditionState = {
  SET_TERMS_AND_COND_STATE: 'TERMS_AND_COND_STATE',
};

export type termsAndConditionStatus = true | false;

export function setTermsAndConditionStatus(payload: termsAndConditionStatus) {
  return {
    type: termsAndConditionState.SET_TERMS_AND_COND_STATE,
    payload,
  };
};
