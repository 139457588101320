import { getODRDocumentPDFRequest } from 'services/DemeritPointsService';
import { AnalyticsService } from '@rmstransactions/components';
import { Constants } from 'utils/helpers';

interface IDownloadOdrConstantsMap {
  REQUEST_DOWNLOAD_ODR: string;
  SUCCESS_DOWNLOAD_ODR: string;
  FAILTURE_DOWNLOAD_ODR: string;
};

// constants
export const downloadOdrConstants: IDownloadOdrConstantsMap = {
  REQUEST_DOWNLOAD_ODR: 'REQUEST_DOWNLOAD_ODR',
  SUCCESS_DOWNLOAD_ODR: 'SUCCESS_DOWNLOAD_ODR',
  FAILTURE_DOWNLOAD_ODR: 'FAILTURE_DOWNLOAD_ODR',
};

// actions
export function getDownloadOdr() {
  return (dispatch: any) => {
    dispatch(request());
    getODRDocumentPDFRequest()
      .then((response: any) => {
        dispatch(success(response.data));
      })
      .catch((error:any) => {
        dispatch(failure(error));
        AnalyticsService.ErrorReportUpgrade(
          Constants.SYSTEMS_ERROR,
          Constants.TECHNICAL_ERROR,
          error,
          new Date(),
          Constants.ERROR_POSITION_BODY,
        );
      });
  };

  function request(data?: Object) {
    return { type: downloadOdrConstants.REQUEST_DOWNLOAD_ODR, data }
  };
  function success(data: Object) {
    return { type: downloadOdrConstants.SUCCESS_DOWNLOAD_ODR, data }
  };
  function failure(data: Object) {
    return { type: downloadOdrConstants.FAILTURE_DOWNLOAD_ODR, data }
  };
};
