import { sendODRDocumentPDFEmailRequest } from 'services/DemeritPointsService';
import { IODRRequestType } from 'interfaces';

// constants
export const odrRequestConstants = {
  REQUEST_ODR_REQUEST: 'REQUEST_ODR_REQUEST',
  SUCCESS_ODR_REQUEST: 'SUCCESS_ODR_REQUEST',
  FAILTURE_ODR_REQUEST: 'FAILTURE_ODR_REQUEST',
  SET_ODR_REQUEST: 'SET_ODR_REQUEST',
};

// actions
export function postOdrRequest(value: IODRRequestType) {
  return (dispatch: any) => {
    dispatch(request(value))
    sendODRDocumentPDFEmailRequest(value)
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        dispatch(failure(error));
      });
  };

  function request(data: Object) {
    return { type: odrRequestConstants.REQUEST_ODR_REQUEST, data }
  };
  function success(data: Object) {
    return { type: odrRequestConstants.SUCCESS_ODR_REQUEST, data }
  };
  function failure(data: Object) {
    return { type: odrRequestConstants.FAILTURE_ODR_REQUEST, data }
  };
};

export function setOdrRequest(data: Object) {
  return {
    type: odrRequestConstants.SET_ODR_REQUEST,
    data,
  };
};

