import React, { createContext, Dispatch, useReducer } from 'react';
import { reducer } from './reducer';

const initialState: AppState = {
  transactionType: 'DPC',
  userId: '',
  transactionStartTime: new Date(),
};

interface AppContext {
  globalState: AppState;
  globalDispatch?: Dispatch<any>;
}

export const globalContextCreated = createContext<AppContext>({} as AppContext);
const { Provider } = globalContextCreated;

const GlobalStateProvider = ({ children }: { children: any }) => {
  const [globalState, globalDispatch] = useReducer(reducer, initialState);

  return (
    <Provider value={{ globalState, globalDispatch }}>{children}</Provider>
  );
};

export default GlobalStateProvider;

export interface AppState {
  transactionType: string;
  userId: string;
  transactionStartTime: Date;
}
