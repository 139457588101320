import { loginAndDemeritsReportConstants } from 'redux/actions';
import { ISystemMessageType } from 'interfaces';
import { errorResolver } from 'utils';
import {
  demeritsErrorMessages,
  drivingRecordErrorMessages,
  reportErrorMessages,
} from 'utils/errorMessages';
import { UserToken } from '@rmstransactions/components';

export const initialState = {
  status: '',
  silentLoginStatus: '',
  generalErrorMessage: '',
  demeritPointsOdr: {},
  demeritPointsReport: {},
  demeritPointsCustomerData: [],
  mostRecentOffenceDate: '',
  secondaryLicenceDetails: {},
  totalDemeritPoints: null,
  maximumPoints: null,
  boolDualClass: null,
  boolDualClassLicenceTypes: null,
  error: {
    odr: [
      {
        message: '',
        severity: '',
        identifier: '',
        state: '',
        errorMessages: {
          header: '',
          message: '',
        },
      },
    ],
    demerits: [
      {
        message: '',
        severity: '',
        identifier: '',
        state: '',
        errorMessages: {
          header: '',
          message: '',
        },
      },
    ],
  },
};

export const loginAndDemeritsReport = (state = initialState, payload: any) => {
  switch (payload.type) {
    case loginAndDemeritsReportConstants.REQUEST_DEMERITS_REPORT:
      return {
        ...state,
        status: 'loading',
      };
    case loginAndDemeritsReportConstants.SUCCESS_DEMERITS_REPORT:
      const demeritsReport = payload.data.demeritPointsReport,
        demeritsOdr = payload.data.demeritPointsOdr;
      UserToken.setValue(payload.headers['x-token']);

      const errorState = {
        demerits: demeritsReport.systemMessages?.message?.map(
          (item: ISystemMessageType) => ({
            severity: item.severity,
            identifier: item.identifier,
            message: item.message,
            state: errorResolver(item.identifier, 'demerits'),
            errorMessages: reportErrorMessages.filter(
              (error) => error.code === item.identifier
            )[0],
          })
        ) || { ...state.error.demerits },
        odr: demeritsOdr.systemMessages?.map((item: ISystemMessageType) => ({
          severity: item.severity,
          identifier: item.identifier,
          message: item.message,
          state: errorResolver(item.identifier, 'odr'),
          errorMessages: drivingRecordErrorMessages.filter(
            (error) => error.code === item.identifier
          )[0],
        })) || { ...state.error.odr },
      };

      const checkOverallError = (errorCollection: any) => {
        const firstErrorCode = errorCollection.demerits[0].identifier,
          secondErrorCode = errorCollection.odr[0].identifier,
          errors = [firstErrorCode, secondErrorCode];

        const result = errors.filter(
          (error, i) => error !== 'Success' && error === errors[i]
        );
        const getUnique = result.filter((v, i, a) => a.indexOf(v) === i);
        return (result.length === 3 && getUnique.length) === 1 ? true : false;
      };

      const setGeneralErrorMessage = (errorCollection): string => {
        if (errorCollection.demerits[0].state === 'GENERAL_ERROR') {
          return errorCollection.demerits[0].errorMessages.message;
        }
        if (errorCollection.odr[0].state === 'GENERAL_ERROR') {
          return errorCollection.odr[0].errorMessages.message;
        }
        //System ErrorPage is not invoked in this scenario and hence returning success
        return 'Success';
      };

      if (
        Array.isArray(demeritsReport.demeritPointsLine) &&
        demeritsReport.demeritPointsLine.length > 0
      ) {
        const sanitisedCustomerData = demeritsReport.demeritPointsLine.map(
          (item) => ({
            licenceClass: item.licenceClass.description,
            licenceTypeCode: item.licenceType.code,
            licenceType: item.licenceType.description,
            totalDemeritPoints: item.demeritPoints.totalDemeritPoints,
            maximumPoints: item.demeritPoints.maximumPoints,
          })
        );
        // sorting to show least restricted licence order first
        const demeritReportSort = sanitisedCustomerData.sort((a, b) =>
          a.licenceTypeCode > b.licenceTypeCode ? 1 : -1
        );
        const itemLast = demeritReportSort.length - 1;
        const licenceTypeItemFirst = demeritReportSort[0].licenceType;
        const licenceTypeItemLast = demeritReportSort[itemLast].licenceType;
        const secondaryLicenceDetails = {
          licenceClass: demeritReportSort[itemLast].licenceClass,
          currentDemerits: demeritReportSort[itemLast].totalDemeritPoints,
          maxDemerits: demeritReportSort[itemLast].maximumPoints,
          remainingDemerits:
            demeritReportSort[itemLast].maximumPoints -
            demeritReportSort[itemLast].totalDemeritPoints,
        };

        const exceptionClasses = [
            'P1 Provisional',
            'Provisional P1',
            'Learner',
          ],
          [filterFirstClass] = exceptionClasses.filter(
            (item) => item === licenceTypeItemFirst
          ),
          [filterSecondClass] = exceptionClasses.filter(
            (item) => item === licenceTypeItemLast
          );

        let boolDualClassLicenceTypes: boolean;
        if (!filterFirstClass || !filterSecondClass) {
          boolDualClassLicenceTypes =
            licenceTypeItemFirst !== licenceTypeItemLast;
        } else {
          boolDualClassLicenceTypes =
            filterFirstClass !== filterSecondClass && false;
        }

        return {
          ...state,
          status: 'success',
          demeritPointsOdr: demeritsOdr,
          demeritPointsReport: demeritsReport,
          demeritPointsCustomerData: sanitisedCustomerData,
          mostRecentOffenceDate: demeritsReport.mostRecentOffenceDate,
          secondaryLicenceDetails: secondaryLicenceDetails,
          totalDemeritPoints:
            demeritsReport.totalDemeritPoints.totalDemeritPoints,
          maximumPoints: demeritsReport.totalDemeritPoints.maximumPoints,
          boolDualClass: sanitisedCustomerData.length > 1,
          boolDualClassLicenceTypes: boolDualClassLicenceTypes,
          error: {
            hasMatchedError: checkOverallError(errorState),
            ...errorState,
          },
          generalErrorMessage: setGeneralErrorMessage(errorState),
        };
      }

      return {
        ...state,
        status: 'success',
        demeritPointsOdr: demeritsOdr,
        demeritPointsReport: demeritsReport,
        error: {
          hasMatchedError: checkOverallError(errorState),
          ...errorState,
        },
        generalErrorMessage: setGeneralErrorMessage(errorState),
      };
    case loginAndDemeritsReportConstants.FAILTURE_DEMERITS_REPORT:
      return {
        ...state,
        status: 'error',
        generalErrorMessage: demeritsErrorMessages.filter(
          (item) => item.code === 'SYSTEM_FAILURE'
        )[0].message,
      };
    case loginAndDemeritsReportConstants.SET_LOGIN_STATUS:
      return {
        ...state,
        silentLoginStatus: payload.data.silentLoginStatus,
        generalErrorMessage: payload.data.statusMessage,
      };
    default:
      return state;
  }
};
