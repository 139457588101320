import React from 'react';
import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';
import { Notification, colors } from '@snsw/react-component-library';

const fontSize = { highRes: '1rem', lowRes: '0.875rem' };

const tableHeader = [
  { value: 'Class' },
  { value: 'Type' },
  { value: 'Demerits' },
];

const DemeritTable = ({
  demeritPointsCustomerData = [],
  totalDemeritPoints,
  dualClass = false,
  dualClassDifferentLicenceType = false,
  secondaryLicenceDetails,
}) => {
  const TableWrapper = styled.table`
    border-bottom: ${!dualClass ? `2px solid ${colors.lightGrey4}` : '0'};
    border-collapse: collapse;
    min-width: 100%;
    width: 100%;
    vertical-align: middle;
    text-align: left;
    font-size: ${fontSize.lowRes};
    margin: 0;

    td {
      padding: 0.6rem 0;
      vertical-align: middle;
      text-align: left;
    }

    th {
      padding: 0.6rem 0;
      vertical-align: bottom;

      &:last-of-type {
        width: 75px;
      }
    }

    ${Breakpoint('sm')`
      font-size: ${fontSize.highRes};

      td {
        padding: 0.6rem 0.5rem;
        vertical-align: middle;
        text-align: left;
      }

      th {
        padding: 0.6rem 0.5rem;

        &:last-of-type {
          width: 90px;
        }
      }
    `}

    .tableHeader {
      font-color: ${colors.blackBlue};
      height: 24px;
      border-bottom: 4px solid ${colors.lightGrey4};
      border-radius: 0;
    }

    tr {
      border-bottom: 2px solid ${colors.lightGrey4};
      height: 48px;

      &:last-of-type {
        border: 0;
      }
    }

    td:last-of-type {
      font-weight: 500;
      text-align: center;
      color: ${colors.darkBlue};
    }
  `;

  const TotalDemeritCountLayout = styled.div`
    width: 100%;
    background: ${colors.greyBackground};
    border-radius: 0;
    display: flex
    justify-content: space-between;
    font-weight: 500;
    padding: 0.75rem 0 0.75rem 0.75rem;
    margin-bottom: 2rem;
    font-size: ${fontSize.lowRes};

    ${Breakpoint('sm')`
      font-size: ${fontSize.highRes};
      padding: 0.75rem 0 0.75rem 0.75rem;
    `}

    .demeritTotalSpan {
      color: ${colors.darkBlue};
      width: 75px;
      text-align: center;

      ${Breakpoint('sm')`
        width: 90px;
      `}
    }
  `;

  const DemeritsSuspendedText = styled.p`
    font-size: 0.85rem;
    margin-top: 0;

    ${Breakpoint('sm')`
      padding: 0 0.5rem;
    `}
  `;

  return (
    <>
      <TableWrapper>
        <thead className="tableHeader">
          <tr>
            {tableHeader.map((item: any) => (
              <th key={item.value}>{item.value}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {demeritPointsCustomerData?.map((item: any, i: number) => (
            <tr key={item.licenceClass}>
              <td className={`licence-class-${i + 1}`}>{item.licenceClass}</td>
              <td className={`licence-type-${i + 1}`}>{item.licenceType}</td>
              <td className={`current-demerit-points-${i + 1}`}>
                {item.totalDemeritPoints}
              </td>
            </tr>
          ))}
        </tbody>
      </TableWrapper>

      {dualClassDifferentLicenceType &&
        secondaryLicenceDetails.licenceClass !== 'Inactive' && (
          <DemeritsSuspendedText>
            {secondaryLicenceDetails.licenceClass}
            {' class will be suspended at '}
            <span className="secondary-suspend-demerit-point">
              {secondaryLicenceDetails.maxDemerits}
            </span>
            {' demerits'}
          </DemeritsSuspendedText>
        )}

      {dualClass && (
        <TotalDemeritCountLayout>
          <span>Total demerits</span>
          <span className="demeritTotalSpan total-demerit-points">
            {totalDemeritPoints}
          </span>
        </TotalDemeritCountLayout>
      )}

      {dualClassDifferentLicenceType &&
        secondaryLicenceDetails.licenceClass !== 'Inactive' && (
          <Notification
            variant="info"
            className="notice-wrapper"
            title={`
            ${secondaryLicenceDetails.licenceClass} class will be suspended at
            ${secondaryLicenceDetails.maxDemerits} demerits
          `}
          >
            <>
              <span className="noticecopy secondary-current-demerit">
                Currently you have {secondaryLicenceDetails.currentDemerits}
                {secondaryLicenceDetails.currentDemerits === 1
                  ? ' demerit'
                  : ' demerits'}
                {' for this class, '}
              </span>
              <span className="noticecopy demerit-remaining">
                {secondaryLicenceDetails.remainingDemerits} more{' '}
                {secondaryLicenceDetails.remainingDemerits === 1
                  ? 'demerit'
                  : 'demerits'}{' '}
                and this class will be suspended.
              </span>
            </>
          </Notification>
        )}
    </>
  );
};

export default DemeritTable;
