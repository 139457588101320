import { combineReducers } from 'redux';
import { customerDetails } from './customerDetails.reducers';
import { loginAndDemeritsReport } from './loginAndDemeritsReport.reducers';
import { downloadOdr } from './downloadOdr.reducers';
import { odrRequest } from './odrRequest.reducers';
import { termsAndConditionReducer } from './termsAndConditionStatus.reducers';
import { globalStateReducer } from './globalState.reducers';
import { validateOdrCdrDrivingRecord } from './validateOdrCdrDrivingRecord.reducers';
import { processPaymentRequest } from './processPaymentsRequest.reducers'
import { drivingRecordCardSelectType } from './drivingRecordCardSelectType.reducers'
import { purchaseSuccessDrivingRecord } from './purchaseSuccessDrivingRecord.reducers';
import { errorMessages } from './errorMessages.reducers';

const rootReducer = combineReducers({
  customerDetails,
  loginAndDemeritsReport,
  downloadOdr,
  odrRequest,
  termsAndConditionReducer,
  globalStateReducer,
  validateOdrCdrDrivingRecord,
  processPaymentRequest,
  drivingRecordCardSelectType,
  purchaseSuccessDrivingRecord,
  errorMessages
});

export default rootReducer;
