// @ts-nocheck
import React, { useState, useEffect, useRef, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
  validateOdrCdrDrivingRecord,
  processPaymentsRequest,
} from 'redux/actions';
import {
  InlineError,
  Button,
  InPageAlert,
} from '@snsw/react-component-library';
import { Heading } from '@snsw/react-component-library';
import {
  IconNotificationError,
  IconGlobe,
  IconClock,
  IconNotificationSuccess,
  IconEmail,
  ComponentLoader,
  ContentContainer,
  Col,
  Row,
  External,
} from '@snsw/react-component-library';
import {
  Header,
  Spacer,
  helpers,
  Quickfeed,
  KioskService,
  AnalyticsService,
} from '@rmstransactions/components';
import DrivingRecordCardSelection from '../../components/DrivingRecord/DrivingRecordCardSelection';
import {
  ButtonGroup,
  PriceDetailsWrapper,
  IconLabelWrapper,
  PriceDetailsContainer,
  QuitStyle,
  AlertContainer,
} from './styled';
import BuyCertifiedDrivingRecord from '../../components/DrivingRecord/BuyCertifiedDrivingRecord';
import PurchasedUncertifiedRecord from '../../components/DrivingRecord/PurchasedUncertifiedRecord';
import { convertToUpperCase, Constants } from 'utils/helpers';
import { history } from 'utils';
import { ODRCDRSession } from '../../session/ODRCDRSession';
import { ErrorPage } from 'components';
import TimeoutPage from '../TimeoutPage/TimeoutPage';
import { AppState, globalContextCreated } from 'providers/globalState';

const DrivingRecord = () => {
  KioskService.sendMessage();
  const { globalState } = useContext(globalContextCreated) as {
    globalState: AppState;
    globalDispatch: any;
  };
  const [udrCardStatus, setUdrCardStatus] = useState('default');
  const [cdrCardStatus, setCdrCardStatus] = useState('default');
  const [showBuyCdrDetails, setShowBuyCdrDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const ref = useRef(null);
  const dispatch = useDispatch();
  const {
    status,
    customerGivenName,
    customerFamilyName,
    licenceId,
    odrNetAmount,
    odrGstAmount,
    odrGrossAmount,
    cdrNetAmount,
    cdrGstAmount,
    cdrGrossAmount,
    odrPurchased,
    cdrPurchased,
    odrAvailable,
    drivingRecordCardSelectType,
    costOdr,
    costCdr,
    purchasedUDRDate,
    purchasedODRUUID,
    mailingAddress,
  } = useSelector((state: any) => ({
    status: state.validateOdrCdrDrivingRecord?.status,
    customerGivenName:
      state.validateOdrCdrDrivingRecord?.demeritPointsOdr?.customer
        ?.customerName?.givenName,
    customerFamilyName:
      state.validateOdrCdrDrivingRecord?.demeritPointsOdr?.customer
        ?.customerName?.familyName,
    licenceId:
      state.validateOdrCdrDrivingRecord?.demeritPointsOdr?.currentLicence
        ?.licenceID,
    odrNetAmount:
      state.validateOdrCdrDrivingRecord?.demeritPointsOdr?.fee?.netAmount,
    odrGstAmount:
      state.validateOdrCdrDrivingRecord?.demeritPointsOdr?.fee?.gstAmount,
    odrGrossAmount:
      state.validateOdrCdrDrivingRecord?.demeritPointsOdr?.fee?.grossAmount,
    cdrNetAmount:
      state.validateOdrCdrDrivingRecord?.demeritPointsCdr?.fee?.netAmount,
    cdrGstAmount:
      state.validateOdrCdrDrivingRecord?.demeritPointsCdr?.fee?.gstAmount,
    cdrGrossAmount:
      state.validateOdrCdrDrivingRecord?.demeritPointsCdr?.fee?.grossAmount,
    odrPurchased:
      state.validateOdrCdrDrivingRecord?.demeritPointsOdr?.purchased,
    cdrPurchased:
      state.validateOdrCdrDrivingRecord?.demeritPointsCdr?.purchased,
    odrAvailable:
      state.validateOdrCdrDrivingRecord?.demeritPointsCdr?.odrAvailable,
    costOdr:
      state.validateOdrCdrDrivingRecord?.demeritPointsOdr?.fee?.netAmount,
    costCdr:
      state.validateOdrCdrDrivingRecord?.demeritPointsCdr?.fee?.netAmount,
    drivingRecordCardSelectType: state.drivingRecordCardSelectType?.type,
    purchasedUDRDate:
      state.validateOdrCdrDrivingRecord?.demeritPointsOdr?.purchasedDate,
    purchasedODRUUID:
      state.validateOdrCdrDrivingRecord?.demeritPointsOdr?.purchasedUUID,
    mailingAddress:
      state.validateOdrCdrDrivingRecord?.demeritPointsCdr?.mailingAddress,
  }));
  const customerName =
    helpers.sentenceCase(customerGivenName) +
    ' ' +
    convertToUpperCase(customerFamilyName);
  const pageUrl =
    window.location.protocol +
    Constants.FORWARD_DOUBLE_SLASH +
    window.location.hostname +
    Constants.DRIVING_RECORD_PAGE_URL;

  useEffect(() => {
    document.title = 'Service NSW - Request Driving Record';
    // @ts-ignore
    dispatch(validateOdrCdrDrivingRecord());
    AnalyticsService.PageViewUpgrade(
      Constants.DRIVING_RECORD_PAGE,
      pageUrl,
      '',
      globalState.transactionStartTime
    );
  }, []); // eslint-disable-line

  const handleButtonClickGA = (
    eventName: String,
    eventAction: String,
    destinationUrl: String
  ) => {
    AnalyticsService.ButtonClickUpgrade(
      eventAction,
      eventName,
      globalState.transactionStartTime,
      destinationUrl
    );
  };
  const onBackButtonClick = (eventName: String, eventAction: String) => {
    if (showBuyCdrDetails) {
      setShowBuyCdrDetails(false);
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    } else {
      let url: string =
        window.location.protocol +
        Constants.FORWARD_DOUBLE_SLASH +
        window.location.hostname;
      let backUrl: string = '';
      if (ODRCDRSession.getCameFromGetYourDrivingRecord() === 'yes') {
        backUrl = Constants.DEMERITS_SUMMARY_PAGE_URL;
      } else if (ODRCDRSession.getCameFromBuyDrivingRecord() === 'yes') {
        backUrl = Constants.DRIVING_RECORD_PAGE_URL;
      } else {
        backUrl = Constants.ODR_CDR_URL;
      }
      handleButtonClickGA(eventName, eventAction, url + backUrl);
      history.push(backUrl);
    }
  };

  const onBuyCertifiedDRButtonClick = (
    eventName: String,
    eventAction: String
  ) => {
    handleButtonClickGA(eventName, eventAction, pageUrl);
    ODRCDRSession.setCameFromBuyDrivingRecord();
    setShowBuyCdrDetails(true);
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
    ref.current?.scrollIntoView({ behavior: 'smooth' });
  };

  const continueToPayment = (
    e: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    e.preventDefault();
    if (
      udrCardStatus === 'selected' ||
      cdrCardStatus === 'selected' ||
      showBuyCdrDetails
    ) {
      setIsLoading(true);
      // @ts-ignore
      dispatch(
        processPaymentsRequest({
          amount:
            udrCardStatus === 'selected' ? odrGrossAmount : cdrGrossAmount,
          kioskId: KioskService.isKiosk() ? KioskService.getKioskId() : '',
          transactionType:
            drivingRecordCardSelectType === 'udrCard' ? 'ODR' : 'CDR',
        })
      );
    }
    if (udrCardStatus === 'default' && cdrCardStatus === 'default') {
      setUdrCardStatus('error');
      setCdrCardStatus('error');
    }
  };

  const returnQuitUrl = (eventName: String, eventAction: String) => {
    if (ODRCDRSession.getTransactionType() === 'DPC') {
      ODRCDRSession.clearAll();
      handleButtonClickGA(eventName, eventAction, Constants.QUIT_DPC_URL);
      return (window.location.href = Constants.QUIT_DPC_URL);
    } else if (ODRCDRSession.getTransactionType() === 'ODRCDR') {
      ODRCDRSession.clearAll();
      handleButtonClickGA(eventName, eventAction, Constants.QUIT_ODRCDR_URL);
      return (window.location.href = Constants.QUIT_ODRCDR_URL);
    }
  };

  const currentUrl = window.location.pathname.split('/');
  const currentHost = currentUrl[0];
  const demeritUrl = currentHost + '/demerit-enquiry/summary';
  const drivingRecordContent = (
    <>
      <TimeoutPage />
      <Header data-testid='header' title='Your driving record' />
      <ContentContainer>
        <Row>
          <Col span={7}>
            <Spacer mt={-2} mb={-2}>
              {ODRCDRSession.getTransactionType() === 'ODRCDR' &&
                odrPurchased === 'N' &&
                odrAvailable === 'N' && (
                  <AlertContainer className='pageAlert'>
                    <InPageAlert
                      title='Did you know you can see your demerit points for free?'
                      variant='info'
                      data-testid='dpcAlert'
                    >
                      <External href={demeritUrl}>
                        Check your demerit points
                      </External>
                    </InPageAlert>
                  </AlertContainer>
                )}
              <Spacer mt={2}>
                <p>
                  <strong>{customerName}</strong>
                  <br />
                  Licence No.<strong> {licenceId}</strong>
                </p>
              </Spacer>
              <hr />
              <Spacer mt={2} mb={2}>
                {' '}
              </Spacer>
            </Spacer>
            {odrPurchased !== 'Y' &&
              cdrPurchased !== 'Y' &&
              odrAvailable !== 'Y' && (
                <Spacer mb={2.5}>
                  <Heading level={2}>Which record do you need?</Heading>
                  <DrivingRecordCardSelection
                    costOdr={costOdr}
                    costCdr={costCdr}
                    udrCardStatus={udrCardStatus}
                    setUdrCardStatus={setUdrCardStatus}
                    cdrCardStatus={cdrCardStatus}
                    setCdrCardStatus={setCdrCardStatus}
                  />
                </Spacer>
              )}
            {udrCardStatus === 'selected' && (
              <Spacer mb={2}>
                <Heading level={2}>You'll receive:</Heading>
                <Heading level={3}>An uncertified record</Heading>
                <Spacer mb={1.5} mt={-0.5}>
                  <IconLabelWrapper>
                    <div className='iconWrapper'>
                      <IconNotificationError />
                    </div>
                    <p>Cannot be used for legal proceedings</p>
                  </IconLabelWrapper>
                  <IconLabelWrapper>
                    <div className='iconWrapper'>
                      <IconClock />
                    </div>
                    <p>Shows a history of offences against your licence</p>
                  </IconLabelWrapper>
                  <IconLabelWrapper>
                    <div className='iconWrapper'>
                      <IconGlobe />
                    </div>
                    <p>Access online for 7 days</p>
                  </IconLabelWrapper>
                </Spacer>
                <hr />
                <Spacer mb={1.5}>
                  <Heading level={3}>Your order</Heading>
                  <PriceDetailsContainer>
                    <PriceDetailsWrapper>
                      <div className='priceDetails'>
                        <div data-testid='priceName'>
                          Uncertified driving record
                        </div>
                        <div data-testid='priceAmount'>
                          ${(Math.round(odrNetAmount * 100) / 100).toFixed(2)}
                        </div>
                      </div>
                      <div className='priceDetails'>
                        <div data-testid='priceName'>GST</div>
                        <div data-testid='priceAmount'>
                          ${(Math.round(odrGstAmount * 100) / 100).toFixed(2)}
                        </div>
                      </div>
                      <div className='priceDetails'>
                        <div data-testid='priceName'>
                          <strong className='totalAmount'>Total cost</strong>
                        </div>
                        <div data-testid='priceAmount'>
                          <strong className='totalAmount'>
                            $
                            {(Math.round(odrGrossAmount * 100) / 100).toFixed(
                              2
                            )}
                          </strong>
                        </div>
                      </div>
                    </PriceDetailsWrapper>
                  </PriceDetailsContainer>
                </Spacer>
              </Spacer>
            )}
            <>
              <Spacer mt={-0.5} />
              <Heading level={2}>
                {odrAvailable === 'Y' &&
                  cdrPurchased === 'N' &&
                  showBuyCdrDetails &&
                  `Buy another certified record for ${helpers.AUD(
                    cdrGrossAmount
                  )}`}
                {showBuyCdrDetails &&
                  odrPurchased === 'Y' &&
                  odrAvailable !== 'Y' &&
                  `Buy a certified record for ${helpers.AUD(cdrGrossAmount)}`}
              </Heading>
              {showBuyCdrDetails && (
                <>
                  <Spacer mt={-1} />
                  <Heading level={3}>You'll receive:</Heading>
                  <Spacer mt={-1} />
                </>
              )}
            </>

            {cdrCardStatus === 'selected' && (
              <>
                <Heading level={2}>You'll receive:</Heading>
              </>
            )}
            {(cdrCardStatus === 'selected' || showBuyCdrDetails) && (
              <>
                <BuyCertifiedDrivingRecord
                  cdrNetAmount={cdrNetAmount}
                  cdrGstAmount={cdrGstAmount}
                  cdrGrossAmount={cdrGrossAmount}
                />
              </>
            )}
            {((odrPurchased !== 'Y' &&
              cdrPurchased !== 'Y' &&
              odrAvailable !== 'Y') ||
              showBuyCdrDetails) && (
              <ButtonGroup>
                <Spacer mt={3.5} mb={1.5}>
                  {(udrCardStatus === 'error' || cdrCardStatus === 'error') &&
                    !showBuyCdrDetails && (
                      <Spacer mb={2} mt={-2}>
                        <InlineError errorMessage='Select the record you need' />
                      </Spacer>
                    )}
                  <Button
                    data-testid='continueBtn'
                    onClick={continueToPayment}
                    className='continue'
                  >
                    <b>Continue to payment</b>
                  </Button>
                  <Spacer mt={1} className='mobile-spacer' />
                  <Button
                    id='backBtm'
                    data-testid='backbtn'
                    theme='secondary'
                    className='back'
                    onClick={() =>
                      onBackButtonClick('Back', 'button_secondary')
                    }
                  >
                    <b>Back</b>
                  </Button>
                </Spacer>
              </ButtonGroup>
            )}
            {(odrAvailable === 'Y' || odrPurchased === 'Y') &&
              !showBuyCdrDetails && (
                <>
                  <Spacer mt={3} className='mobile-spacer' />
                  <Spacer mt={-0.5} />
                  <Heading level={2}>Purchased Records:</Heading>
                </>
              )}
            {odrAvailable === 'Y' && !showBuyCdrDetails && (
              <>
                <Spacer mt={-1} />
                <Heading level={3}>Your certified driving record</Heading>
                <Spacer mt={-0.5} />
                <IconLabelWrapper>
                  <div className='iconWrapper'>
                    <IconNotificationSuccess />
                  </div>
                  <p>Can be used for legal proceedings</p>
                </IconLabelWrapper>
                <IconLabelWrapper>
                  <div className='iconWrapper'>
                    <IconEmail />
                  </div>
                  <p>
                    We've mailed your certified driving record to
                    {mailingAddress ? (
                      <>
                        <br />
                        <strong>
                          {mailingAddress}
                          <br />
                        </strong>
                      </>
                    ) : (
                      <>&nbsp;you.&nbsp;</>
                    )}
                    This will arrive within 7 days.
                  </p>
                </IconLabelWrapper>
                <Spacer mt={-1.5} />
              </>
            )}
            {cdrPurchased === 'Y' && (
              <>
                <p>
                  Please wait 24 hours to purchase another certified driving
                  record
                </p>
              </>
            )}
            {(odrAvailable === 'Y' || odrPurchased === 'Y') &&
              !showBuyCdrDetails && (
                <>
                  <PurchasedUncertifiedRecord
                    uuid={purchasedODRUUID}
                    purchasedUDRDate={purchasedUDRDate}
                    customerName={customerName}
                  />
                  <Spacer mb={2} />
                  <hr />
                </>
              )}
            {odrPurchased === 'Y' &&
              cdrPurchased !== 'Y' &&
              odrAvailable !== 'Y' &&
              !showBuyCdrDetails && (
                <>
                  <Spacer mt={-0.5} />
                  <Heading level={2}>
                    Buy a certified record for {helpers.AUD(cdrGrossAmount)}
                  </Heading>
                </>
              )}
            {cdrPurchased !== 'Y' &&
              odrAvailable === 'Y' &&
              !showBuyCdrDetails && (
                <>
                  <Spacer mt={-0.5} />
                  <Heading level={2}>
                    Buy another certified record for{' '}
                    {helpers.AUD(cdrGrossAmount)}
                  </Heading>
                </>
              )}
            {((cdrPurchased !== 'Y' && odrAvailable === 'Y') ||
              (odrPurchased === 'Y' &&
                cdrPurchased !== 'Y' &&
                odrAvailable !== 'Y')) &&
              !showBuyCdrDetails && (
                <>
                  <p>
                    A certified driving record shows a history of offences. If
                    you've accrued any demerit points, they will appear on your
                    record.
                  </p>
                  <p>
                    A certified driving record can be used for legal proceedings
                    and will be mailed to you within 7 days.
                  </p>
                  <Spacer mt={1.5} />
                  <Button
                    id='buyCertifiedRecord'
                    theme='secondary'
                    className='back'
                    onClick={() =>
                      onBuyCertifiedDRButtonClick(
                        'Buy a certified record',
                        'button_secondary'
                      )
                    }
                    data-testid='buyCertifiedRecord'
                  >
                    <b>Buy a certified record</b>
                  </Button>
                  <Spacer mt={2} />
                  <hr />
                </>
              )}
            {(odrPurchased === 'Y' ||
              cdrPurchased === 'Y' ||
              odrAvailable === 'Y') &&
              !showBuyCdrDetails && (
                <>
                  <QuitStyle>
                    <Spacer mt={2}>
                      <Button
                        className='button_quit'
                        theme='primary'
                        onClick={() => returnQuitUrl('Quit', 'button_primary')}
                        data-testid='button_quit'
                      >
                        <b>Quit</b>
                      </Button>
                    </Spacer>
                    <Spacer mt={2} mb={2}>
                      <Button
                        className='button_survey'
                        variant='secondary'
                        onClick={() => {
                          handleButtonClickGA(
                            'Help us Improve',
                            'button_secondary',
                            Constants.HELP_US_IMPROVE_DRIVING_RECORD
                          );
                          window.location.href =
                            Constants.HELP_US_IMPROVE_DRIVING_RECORD;
                        }}
                        data-testid='button_survey'
                        external
                      >
                        <strong> Help us improve</strong>
                      </Button>
                    </Spacer>
                  </QuitStyle>
                  <Spacer mb={2.5}>
                    <Quickfeed
                      sourceApplication='driving-record'
                      label='How was your driving record purchase experience?'
                      environment={process.env.REACT_APP_TUTD}
                    />
                  </Spacer>
                </>
              )}
          </Col>
        </Row>
      </ContentContainer>
    </>
  );
  return (
    <>
      {(status === '' || status === 'error') && <ErrorPage />}
      {status === 'success' && drivingRecordContent}
      <ComponentLoader
        active={status === 'loading' || isLoading}
        fullPage={true}
        label='One moment please...'
      />
    </>
  );
};

export default DrivingRecord;
