import { drivingRecordCardSelectTypeConstants } from 'redux/actions';
export const initialState = {
    type: ''
};

export const drivingRecordCardSelectType = (state = initialState, action: any) => {

  switch (action.type) {
    case drivingRecordCardSelectTypeConstants.SET_DRIVING_RECORD_CARD_SELECTION_TYPE:
      return {
        ...state,
        type: action.payload,
      }
    default:
      return {
        ...state,
        type: ''
      }
  }
};