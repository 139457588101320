import React from 'react';
import { Button, Heading } from '@snsw/react-component-library';
import { Spacer, Svg, helpers } from '@rmstransactions/components';
import styled from 'styled-components';

const LastOffenceBoxWrapper = styled.div`
  margin-bottom: 1rem;

  h3 {
    margin: 0 0 0.5rem 0;
  }
`;

const DateWrapper = styled.div`
  display: flex;
  justify-content: flex-start;

  svg {
    margin-top: -2px;
  }

  span {
    margin-left: 0.65rem;
  }
`;

const LastOffenceBox = ({ lastOffenceDate = '' }) => {
  return (
    <LastOffenceBoxWrapper>
      <Heading level={3}>Last offence recorded</Heading>
      {lastOffenceDate != null && (
        <DateWrapper>
          <Svg.Calendar />
          <span data-testid="last-offence-date">
            {helpers.dateFormat(lastOffenceDate, 'D MMMM YYYY')}
          </span>
        </DateWrapper>
      )}
      <Spacer mb={1.5}>
        <p>
          {lastOffenceDate != null ? 'Only' : 'No'} offences with active demerit
          points recorded in the last 3 years and 4 months
          {lastOffenceDate !== null && ' are shown'}. Find out{' '}
          <Button
            theme="link"
            href="https://www.nsw.gov.au/driving-boating-and-transport/demerits-penalties-and-offences/how-demerit-points-work"
            external
          >
            how demerits work
          </Button>
        </p>
      </Spacer>
      <b>
        Please note: You can't print this information to use in legal
        proceedings, only certified driving records can be used.
      </b>
    </LastOffenceBoxWrapper>
  );
};

export default LastOffenceBox;
