import React, { useState, useRef } from 'react';
import {
  Heading,
  IconDownload,
  IconNotificationError,
  IconCalendar,
  Notification, Button, colors
} from '@snsw/react-component-library';
import {DisableTelOnTabletDesktop, Spacer, Svg} from '@rmstransactions/components';
import { IconLabelWrapper } from '../../pages/DrivingRecord/styled';
import { getDrivingRecordDocument } from '../../services/OdrCdrService';
import {getDrivingRecordAccessUntilDate, processPdf} from 'utils/helpers';
import { EmailForm } from '../../components';
import { history } from '../../utils';

interface PurchasedUncerterfinedRecordProps {
  uuid: string;
  purchasedUDRDate: string;
  customerName: string;
}

const PurchasedUncertifiedRecord: React.FC<PurchasedUncerterfinedRecordProps> = ({
  uuid,
  purchasedUDRDate,
  customerName
}) => {

  const [documentState, setDocumentState] = useState<string>('');
  const emailInputRef = useRef<HTMLInputElement | null>(null);
  const fileName = `uncertified_driving_record_${customerName}_${purchasedUDRDate}.pdf`;
  const onDownloadUdrButton = () => {
    setDocumentState('loading')
    getDrivingRecordDocument(uuid)
      .then((response: any) => {
        if (response.data.size === 0){
          setDocumentState('error')
        } else  {
          setDocumentState('')
          processPdf(response.data, fileName)
        }
      }).catch((error: any) => {
        setDocumentState('error');
        history.push('/error');
      });
  };

  return (
    <>
      <Spacer mt={-0.5} />
      <Heading level={3}>Your uncertified driving record</Heading>
      <Spacer mb={-0.5} mt={-0.5}>
        {documentState === 'error' && (
          <Notification
            variant='error'
            title={
              <span
                style={{
                  fontWeight: 400,
                  marginBottom: '0.25rem',
                  display: 'block',
                }}
              >
                We’re unable to retrieve your uncertified driving record.
                      Please try again. Need help? Call us on{' '}
                <DisableTelOnTabletDesktop phoneNumber='13 77 88' /> or visit
                      a{' '}
                <a
                  href='https://www.service.nsw.gov.au/service-centre'
                  style={{ color: `${colors.blue}` }}
                  target='_blank'
                  rel='noopener noreferrer'
                >
                    service centre
                  </a>
                  .
              </span>
            }
            role='alert'
            className='notice-wrapper'
          />
        )}
        <IconLabelWrapper>
          <div className='iconWrapper'>
            <IconNotificationError />
          </div>
          <p>Cannot be used for legal proceedings</p>
        </IconLabelWrapper>
        <IconLabelWrapper>
          <div className='iconWrapper'>
            <IconDownload />
          </div>
          <Button
            variant='link'
            onClick={onDownloadUdrButton}
            style={{
              backgroundColor: 'transparent',
              border: 'none',
              outline: 'none',
              color: '#2e5299',
              fontWeight: '100',
              minWidth: '0px',
              padding: '0px',
              margin_bottom: '5px'
            }}
          >
            {documentState === 'loading' ? <Svg.Spinner color="#646974" /> : 'Download uncertified driving record'}
          </Button>
        </IconLabelWrapper>
        <Spacer mb={1} />
        <IconLabelWrapper>
          <div className='iconWrapper'>
            <IconCalendar />
          </div>
          <p>
            Access until {getDrivingRecordAccessUntilDate(purchasedUDRDate)}
          </p>
        </IconLabelWrapper>
      </Spacer>
      <EmailForm uuid={uuid} emailInputRef={emailInputRef} />
    </>
  );
};
export default PurchasedUncertifiedRecord;
