import { purchasedSuccess } from 'services/OdrCdrService';
import { history } from "../../utils";
import { AnalyticsService } from '@rmstransactions/components';
import { Constants } from 'utils/helpers';

// constants
export const purchaseSuccessConstants = {
  REQUEST_PURCHASE_SUCCESS: 'REQUEST_PURCHASE_SUCCESS',
  SUCCESS_PURCHASE_SUCCESS: 'SUCCESS_PURCHASE_SUCCESS',
  FAILURE_PURCHASE_SUCCESS: 'FAILURE_PURCHASE_SUCCESS',
  SET_LOGIN_STATUS: 'SET_LOGIN_STATUS',
  ODR: 'odr',
  CDR: 'cdr',
  SUCCESS: 'success',
  SYSTEM_FAILURE: 'SYSTEM_FAILURE',
  LOADING: 'loading',
  GENERAL_ERROR: 'GENERAL_ERROR',
  ERROR: 'error'
};

// actions
export function purchaseSuccessDrivingRecord(uuid: string) {
  return (dispatch: any) => {

    if (process.env.REACT_APP_MOCK_TEST) {
      try {
        const userData = require(`./../../utils/mockData/odrcdr/purchaseSuccess`);
        const purchaseSuccessDrivingRecord = userData.default;
        const headers = []
        headers["x-token"] = "ytutuutj"
        dispatch(success(purchaseSuccessDrivingRecord, headers));
      } catch (e) {
        console.assert(true, purchasedSuccess(uuid));
        dispatch(failure(e));
      }
      return;
    };

    dispatch(request());
    purchasedSuccess(uuid)
      .then((response) => {
          if (response?.data?.messages[0].severity === 'SUCCESS') {
            dispatch(success(response.data, response.headers));
            return;
          } else {
            dispatch(failure(response.data?.messages[0]));
            AnalyticsService.ErrorReport(
              Constants.SYSTEMS_ERROR,
              Constants.BUISNESS_ERROR,
              response?.data?.messages[0].severity,
              new Date(),
            );
            history.push('/error')
          }
      })
      .catch((error) => {
        dispatch(failure(error));
        AnalyticsService.ErrorReportUpgrade(
          Constants.SYSTEMS_ERROR,
          Constants.TECHNICAL_ERROR,
          error,
          new Date(),
          Constants.ERROR_POSITION_BODY
        );
        history.push('/error')
      });
  };

  function request(data?: Object) {
    return { type: purchaseSuccessConstants.REQUEST_PURCHASE_SUCCESS, data }
  };
  function success(data: Object, headers: Object) {
    return { type: purchaseSuccessConstants.SUCCESS_PURCHASE_SUCCESS, data, headers }
  };
  function failure(data: Object) {
    return { type: "errorMessages", data }
  };
};
