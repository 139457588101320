import { downloadOdrConstants } from 'redux/actions';
import { helpers } from '@rmstransactions/components';
import { drivingRecordErrorMessages } from 'utils/errorMessages';

const { REACT_APP_DEBUG } = process.env;

export const initialState = {
  status: '',
  errorNoticeTitle: '',
  errorNoticeCopyMessage: '',
  response: {},
};

export const downloadOdr = (state = initialState, payload: any) => {
  switch (payload.type) {
    case downloadOdrConstants.REQUEST_DOWNLOAD_ODR:
      return {
        ...state,
        status: 'loading',
      }
    case downloadOdrConstants.SUCCESS_DOWNLOAD_ODR:

      const validateDownloadPDFResponse = (res: any) => {
        if (res.data !== null && res.data.messages !== null) {
          for (let i = 0; i < Object.keys(res.data.messages).length; i++) {
            if (res.data.messages.message[i].identifier === 'SUCCESS') {
              return true;
            }
          }
        };
        // here all error cases included (ODR_NOT_AVAILABLE, UNABLE_GENERATE_PDF),
        // if we need to handle anyone specifically we can put the condition here, all data is here from backend
        return false;
      };

      const generateODRFileName = () => (
        `Online Driving Record ${helpers.dateFormat('', 'YYYY-MM-DD_HH.mm.ss')}.pdf`
      );

      const base64ToPdfBlob = (base64: string) => {
        if (REACT_APP_DEBUG) console.log(`the base64 string`, base64);
        const binaryString = atob(base64);
        const len = binaryString.length;
        const bytes = new Uint8Array(len);
        for (let i = 0; i < len; ++i) {
          bytes[i] = binaryString.charCodeAt(i);
        }

        return new Blob([bytes], { type: 'application/pdf' });
      };

      // success response
      if (validateDownloadPDFResponse(payload)) {
        const blobObject = base64ToPdfBlob(payload.data.pdfEncodedBytes);
        const url = window.URL.createObjectURL(blobObject);
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', generateODRFileName());
        document.body.appendChild(link);
        link.click();

        return {
          ...state,
          status: 'success',
          response: payload.data,
          systemMessages: payload.data.messages,
        }
      };

      return {
        ...state,
        status: 'error',
        response: payload.data,
        systemMessages: payload.data.messages,
        errorNoticeTitle: drivingRecordErrorMessages.filter(error => error.code === payload.data.messages.message[0].identifier)[0].header,
        errorNoticeCopyMessage: drivingRecordErrorMessages.filter(error => error.code === payload.data.messages.message[0].identifier)[0].message,
      }
    case downloadOdrConstants.FAILTURE_DOWNLOAD_ODR:
      return {
        ...state,
        status: 'error',
      }
    default:
      return state;
  }
};
