import { getCustomerDetails } from 'services/DemeritPointsService';
import { history } from "../../utils";

// constants
export const customerDetailsConstants = {
  REQUEST_CUSTOMER_DETAILS: 'REQUEST_CUSTOMER_DETAILS',
  SUCCESS_CUSTOMER_DETAILS: 'SUCCESS_CUSTOMER_DETAILS',
  FAILTURE_CUSTOMER_DETAILS: 'FAILTURE_CUSTOMER_DETAILS',
};

// actions
export function getCustomerEmailDetails() {
  return (dispatch: any) => {

    if (process.env.REACT_APP_MOCK_TEST) {
      try {
        const customerId = process.env.REACT_APP_CUSTOMER_ID || '';
        const userData = require(`./../../utils/mockData/customerDetails/${customerId}`);
        const customerDetails = userData.default;
        dispatch(success(customerDetails));
      } catch (e) {
        console.assert(true, getCustomerDetails());
        dispatch(failure(e));
      }
      return;
    };

    dispatch(request());
    getCustomerDetails()
      .then((response) => {
        dispatch(success(response.data));
      })
      .catch((error) => {
        dispatch(failure(error));
        history.push('/error')
      });
  };

  function request(data?: Object) {
    return { type: customerDetailsConstants.REQUEST_CUSTOMER_DETAILS, data }
  };
  function success(data: Object) {
    return { type: customerDetailsConstants.SUCCESS_CUSTOMER_DETAILS, data }
  };
  function failure(data: Object) {
    return { type: customerDetailsConstants.FAILTURE_CUSTOMER_DETAILS, data }
  };
};
