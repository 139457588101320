import React, { useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { getDemeritsReport } from 'redux/actions';
import {
  DialUi,
  DemeritTable,
  LastOffenceBox,
  ErrorNotice,
  ContentFooter,
} from 'components';
import {
  Button,
  ComponentLoader,
  Heading,
  ContentContainer,
  Col,
  Row,
} from '@snsw/react-component-library';
import {
  Header,
  Quickfeed,
  Spacer,
  helpers,
  AnalyticsService,
} from '@rmstransactions/components';
import { ErrorPage } from 'components';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { convertToUpperCase } from 'utils/helpers';
import { ODRCDRSession } from '../../session/ODRCDRSession';
import {  Constants } from 'utils/helpers';
import { AppState, globalContextCreated } from 'providers/globalState';

const SummaryWrapper = styled.div`
  h2 {
    margin: 0 0 0.25rem 0;
  }

  .btn__link {
    text-decoration: none;
    font-weight: 700;
  }

  .btn__survey {
    text-decoration: none;

    svg {
      transition: ease all 0.5s;
    }

    &:hover {
      svg {
        fill: #fff;
      }
    }
  }
`;

const Summary = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { globalState } = useContext(globalContextCreated) as {
    globalState: AppState;
    globalDispatch: any;
  };

  const {
    status,
    customerGivenName,
    customerFamilyName,
    licenceId,
    demeritPointsCustomerData,
    mostRecentOffenceDate,
    secondaryLicenceDetails,
    totalDemeritPoints,
    maximumPoints,
    boolDualClass,
    boolDualClassLicenceTypes,
    errorDemeritsArray,
    errorDemerits,
    errorODR,
    hasMatchedError,
    termsAndConditionStatus,
  } = useSelector((state: any) => ({
    status: state.loginAndDemeritsReport?.status,
    customerGivenName:
      state.loginAndDemeritsReport?.demeritPointsOdr?.customerName?.givenName,
    customerFamilyName:
      state.loginAndDemeritsReport?.demeritPointsOdr?.customerName?.familyName,
    licenceId: state.loginAndDemeritsReport?.demeritPointsOdr?.licenceId,
    demeritPointsCustomerData:
      state.loginAndDemeritsReport?.demeritPointsCustomerData,
    mostRecentOffenceDate: state.loginAndDemeritsReport?.mostRecentOffenceDate,
    secondaryLicenceDetails:
      state.loginAndDemeritsReport?.secondaryLicenceDetails,
    totalDemeritPoints: state.loginAndDemeritsReport?.totalDemeritPoints,
    maximumPoints: state.loginAndDemeritsReport?.maximumPoints,
    boolDualClass: state.loginAndDemeritsReport?.boolDualClass,
    boolDualClassLicenceTypes:
      state.loginAndDemeritsReport?.boolDualClassLicenceTypes,
    errorDemeritsArray: state.loginAndDemeritsReport?.error?.demerits,
    errorDemerits: state.loginAndDemeritsReport?.error?.demerits[0],
    errorODR: state.loginAndDemeritsReport?.error?.odr[0],
    hasMatchedError: state.loginAndDemeritsReport?.error?.hasMatchedError,
    termsAndConditionStatus:
      state.termsAndConditionReducer?.hasAcceptedTermsAndCond,
  }));

  const pageUrl =
    window.location.protocol +
    Constants.FORWARD_DOUBLE_SLASH +
    window.location.hostname +
    Constants.DEMERITS_SUMMARY_PAGE_URL;

  useEffect(() => {
    document.title = 'Service NSW - Check Demerit Point';
    // @ts-ignore
    dispatch(getDemeritsReport());
    AnalyticsService.PageViewUpgrade(
      Constants.DEMERITS_SUMMARY_PAGE,
      pageUrl,
      '',
      globalState.transactionStartTime
    );
  }, []); // eslint-disable-line

  const handleGetDrivingRecord = (eventName: String, eventAction: String) => {
    let url: string =
      window.location.protocol +
      Constants.FORWARD_DOUBLE_SLASH +
      window.location.hostname;
    handleButtonClickGA(
      eventName,
      eventAction,
      url + Constants.DRIVING_RECORD_PAGE_URL
    );
    ODRCDRSession.setCameFromGetYourDrivingRecord();
    history.push(Constants.DRIVING_RECORD_PAGE_URL);
  };

  const handleButtonClickGA = (
    eventName: String,
    eventAction: String,
    destinationUrl: String
  ) => {
    AnalyticsService.ButtonClickUpgrade(
      eventAction,
      eventName,
      globalState.transactionStartTime,
      destinationUrl
    );
  };
  //test comments

  const summaryContent = (
    <>
      <Header
        title={`Demerit points summary ${
          errorDemerits?.state !== '' && errorODR?.state !== ''
            ? '& driving record'
            : ''
        }`}
        linkText='Terms &amp; Conditions'
        backLink={() => (window.location.href = '/')}
      />
      <ContentContainer>
        <Row>
          <Col span={7}>
            {/*NOTE: show one main error message when report / OCD / CDR have same errorCdoe */}
            <Spacer mt={-1} mb={-1}>
              <p>
                <strong>
                  {helpers.sentenceCase(customerGivenName)}{' '}
                  {convertToUpperCase(customerFamilyName)}
                </strong>
              </p>
              {licenceId !== '' && (
                <p>
                  Licence No.<strong> {licenceId}</strong>
                </p>
              )}
            </Spacer>
            {licenceId !== '' && <hr />}

            {hasMatchedError && (
              <ErrorNotice
                message={errorDemerits.errorMessages.message}
                hasQuit={errorODR.state === 'INELIGIBLE'}
              />
            )}

            {!hasMatchedError &&
            errorDemerits?.state !== '' &&
            errorDemeritsArray?.length > 0
              ? errorDemeritsArray.map((err: any, i: number) => (
                  <React.Fragment key={`report-error-${i}`}>
                    <ErrorNotice
                      header={i === 0 ? `${err.errorMessages.header}` : null}
                      message={err.errorMessages.message}
                    />
                  </React.Fragment>
                ))
              : null}

            {errorDemerits?.state === '' ? (
              <>
                <DialUi
                  currentDemerits={totalDemeritPoints}
                  maxDemerits={maximumPoints}
                />
                <Spacer mb={2}>
                  <DemeritTable
                    demeritPointsCustomerData={demeritPointsCustomerData}
                    totalDemeritPoints={totalDemeritPoints}
                    dualClass={boolDualClass}
                    dualClassDifferentLicenceType={boolDualClassLicenceTypes}
                    secondaryLicenceDetails={secondaryLicenceDetails}
                  />
                </Spacer>
              </>
            ) : null}

            {/*
            NOTE: unique case - identifier: CUSTOMER_LIC_CLASS_NOT_ACTIVE
            Requires futher testing it with actual API response, revisit this once we get test data from transport(RMSBB-143)
          */}
            {/* {errorDemerits?.identifier === 'CUSTOMER_LIC_CLASS_NOT_ACTIVE' && (
            <Spacer mb={3}>
              <Notification
                variant='info'
                title={infoMessages['CUSTOMER_LIC_CLASS_NOT_ACTIVE'].header}
                role='status'
                className='notice-wrapper'
              >
                <p dangerouslySetInnerHTML={{ __html: infoMessages['CUSTOMER_LIC_CLASS_NOT_ACTIVE'].message }} />
              </Notification>
            </Spacer>
          )} */}

            {errorDemerits?.state === '' && (
              <Spacer mb={'1.5rem'}>
                <LastOffenceBox lastOffenceDate={mostRecentOffenceDate} />
              </Spacer>
            )}

            {/* NOTE: when CDR, ODR errorCodes are same, show only one error notice */}
            {!hasMatchedError && errorODR?.state === 'INELIGIBLE' && (
              <ErrorNotice
                header={errorODR.errorMessages.header}
                message={errorODR.errorMessages.message}
                hasQuit={errorDemerits?.state === 'INELIGIBLE' ? true : null}
              />
            )}

            {/* CDR & ODR both reponses must be successful to render UI */}
            {errorODR?.identifier === 'SUCCESS' && (
              <Spacer mb={2}>
                <hr />
                <Heading level={3}>Get a driving record</Heading>
                <Spacer mb={2}>
                  <p>
                    A driving record provides information about your driving
                    history. This includes information on your demerit points.
                  </p>
                  <p>There are two types of driving records you can buy:</p>
                  <p>
                    1. An uncertified record which <strong>cannot</strong> be
                    used for legal proceedings. You can download this for 7 days
                    from purchase date.
                    <br />
                    2. A certified record which <strong>can</strong> be used for
                    legal proceedings. This will be mailed to you within 7 days.
                  </p>
                  <p>
                    The use of a driving record may be required by some
                    employers or for certain legal proceedings.
                  </p>
                </Spacer>
                <Button
                  data-testid='button-get-drivingRecord'
                  onClick={() =>
                    handleGetDrivingRecord(
                      'View your driving record',
                      'button_primary'
                    )
                  }
                >
                  <strong>View your driving record</strong>
                </Button>
              </Spacer>
            )}
            <hr />
            {errorDemerits?.state === '' && totalDemeritPoints === 0 && (
              <Spacer mb={1.5}>
                <p>
                  You may be eligible for the&nbsp;
                  <a
                    href='https://www.service.nsw.gov.au/transaction/fair-go-safe-drivers-discounted-licence-renewal'
                    target='_blank'
                    rel='noreferrer noopener'
                  >
                    safe driver discount
                  </a>
                  &nbsp;when you renew your drivers licence.
                </p>
              </Spacer>
            )}

            {status === 'success' && errorDemerits?.state === '' && (
              <>
                <Spacer mt={2} mb={2}>
                  <Button
                    className='btn__survey'
                    variant='secondary'
                    href='https://forms.office.com/r/X1ha62g7s1'
                    external
                  >
                    <strong> Help us improve</strong>
                  </Button>
                </Spacer>
                <Spacer mb={2.5}>
                  <Quickfeed
                    sourceApplication='check-demerits'
                    label='How was your driving record purchase experience?'
                    environment={process.env.REACT_APP_TUTD}
                  />
                </Spacer>
              </>
            )}

            <ContentFooter />
          </Col>
        </Row>
      </ContentContainer>
    </>
  );

  return !termsAndConditionStatus ? (
    <Redirect to='/' />
  ) : (
    <SummaryWrapper>
      {(status === '' ||
        status === 'error' ||
        errorDemerits?.state === 'GENERAL_ERROR' ||
        errorODR?.state === 'GENERAL_ERROR') && <ErrorPage />}
      {status === 'success' &&
        errorDemerits?.state !== 'GENERAL_ERROR' &&
        errorODR?.state !== 'GENERAL_ERROR' &&
        summaryContent}
      <ComponentLoader
        active={status === 'loading'}
        fullPage={true}
        label='One moment please...'
      />
    </SummaryWrapper>
  );
};

export default Summary;
