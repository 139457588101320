import styled from 'styled-components';
import { colors } from '@snsw/react-component-library';
import { Breakpoint } from '@rmstransactions/components/Styled';

export const EmailContainer = styled.div`
  a {
    color: ${colors.blue};
  }

  .buttonWrapper {
    margin-left: 1.5rem;
    margin-top: 1.25rem;
    vertical-align: middle;

    button {
      display: flex;
      justify-content: center;
    }
  }

  .emailInlineWrapper {
    display: flex;
    flex-flow: column;

    ${Breakpoint('sm')`
      flex-flow: row;
    `}

    .field-item,
    .field-item input {
      margin: 0;
    }

    button {
      margin: 0.5rem 0 0 0;
      height: 48px;

      ${Breakpoint('sm')`
        margin: 0 0 0 0.5rem;
        min-width: 115px;
        min-height: 48px;
      `}
    }
  }

  label {
    cursor: pointer;
  }
`;

export const FormEmailWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-content: center;
`;

export const EmailIconContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  margin-bottom: 0.5rem;

  svg {
    margin-top: -1px;
  }

  ${Breakpoint('sm')`
    margin: 0;
  `}

  span {
    padding-left: 0.75rem;
  }
`;

export const EmailIconWrapper = styled.div`
  width: 24px;
`;
