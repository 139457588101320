import React, { useState, useEffect, useContext } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setLoginStatus, setTermsAndConditionStatus } from 'redux/actions';
import {
  FormCheckbox,
  Notification,
  ComponentLoader,
  ContentContainer,
} from '@snsw/react-component-library';
import { useHistory } from 'react-router-dom';
import {
  Header,
  FormWrapper,
  ErrorService,
  UserSession,
  AnalyticsService,
} from '@rmstransactions/components';
import { SubmitButton } from 'components';
import { ModalTermsConditions } from './TermsConditionModal';
import { Config, DRUPAL_DPC_URL, DRUPAL_UDRCDR_URL } from 'config/env';
import { TransactionType, Constants } from 'utils/helpers';
import styled from 'styled-components';
import {
  silentLogin,
  getUserDetails,
} from '../../services/DemeritPointsService';

import { AppState, globalContextCreated } from 'providers/globalState';
import { ODRCDRSession } from '../../session/ODRCDRSession';
import { interceptors } from '../../services/DemeritPointsService';
import TimeoutPage from '../TimeoutPage/TimeoutPage';

const TermsWrapper = styled.div`
  .termsButton {
    margin: 0;
    padding: 0;
    text-decoration: underline;
    cursor: pointer;
    z-index: 1;
  }
`;

const TermsCondition = () => {
  const dispatch = useDispatch(),
    history = useHistory(),
    [valid, setValid] = useState(false),
    [termsState, setTermsState] = useState(false),
    [formTouched, setFormTouched] = useState(false),
    [modalState, setModalState] = useState({
      termsConditions: false,
    }),
    [isLoading, setIsLoading] = useState(true);
  const search = window.location.search;
  const params = new URLSearchParams(search);
  const isLoginRequired = params.get('isLoginRequired');

  const { globalState, globalDispatch } = useContext(globalContextCreated) as {
    globalState: AppState;
    globalDispatch: any;
  };

  const { silentLoginStatus } = useSelector((state: any) => ({
    status: state.loginAndDemeritsReport?.status,
    silentLoginStatus: state.loginAndDemeritsReport?.silentLoginStatus,
  }));

  let transactionType: string = TransactionType.DPC;
  let pageUrl: string =
    window.location.protocol +
    Constants.FORWARD_DOUBLE_SLASH +
    window.location.hostname;

  if (window.location.pathname === '/odr-cdr') {
    transactionType = TransactionType.ODRCDR;
    pageUrl = pageUrl + '/odr-cdr';
  } else {
    transactionType = TransactionType.DPC;
  }

  const handleButtonClickGA = (
    eventName: String,
    eventAction: String,
    destinationUrl: String
  ) => {
    AnalyticsService.ButtonClickUpgrade(
      eventAction,
      eventName,
      globalState.transactionStartTime,
      destinationUrl
    );
  };

  useEffect(() => {
    if (transactionType === TransactionType.ODRCDR)
      document.title = 'Service NSW - Request Driving Record';
    else document.title = 'Service NSW - Check Demerit Point';
    interceptors(history, Config.apiHost, transactionType, DRUPAL_DPC_URL);
    ODRCDRSession.clearAll();
    ODRCDRSession.setTransactionType(transactionType);
    globalDispatch({
      type: 'set_transactionType',
      payload: transactionType,
    });
    if (process.env.REACT_APP_MOCK_TEST) {
      setIsLoading(false);
      dispatch(
        setLoginStatus({
          silentLoginStatus: 'success',
          statusMessage: 'test',
        })
      );
      return;
    }
    if (UserSession.isSilentLoginCalled()) {
      UserSession.resetSilentLoginCalled();
      if (isLoginRequired === 'false') {
        sessionStorage.setItem('activeSession', 'yes');
        dispatch(
          setLoginStatus({
            silentLoginStatus: 'success',
            statusMessage: 'Login Success',
          })
        );
        getUserDetails().then((response) => {
          if (response?.data !== '') {
            let userId = response.data;
            AnalyticsService.PageViewUpgrade(
              Constants.TERMS_CONDITIONS_PAGE,
              pageUrl,
              userId,
              globalState.transactionStartTime
            );
          }
        });
      } else {
        let error = { message: '401 Unauthorized' };
        UserSession.resetActiveSession();
        ErrorService.handleAPIErrorForAuthTrans(
          error,
          history,
          Config.apiHost,
          transactionType,
          transactionType === TransactionType.ODRCDR
            ? DRUPAL_UDRCDR_URL
            : DRUPAL_DPC_URL
        );
      }
      setIsLoading(false);
    } else {
      UserSession.setSilentLoginCalled();
      silentLogin(transactionType);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch]);

  useEffect(() => {
    if (termsState) {
      setValid(true);
    } else {
      setValid(false);
    }
  }, [termsState]);

  const termsConditionsContent = (
    <>
      <TimeoutPage />
      <Header
        title='Terms &amp; Conditions'
        linkText='Previous'
        backLink={
          window.location.pathname === '/request-driving-record'
            ? () =>
                (window.location.href =
                  'https://www.service.nsw.gov.au/transaction/request-driving-record')
            : () =>
                (window.location.href =
                  'https://www.service.nsw.gov.au/transaction/check-your-demerit-points')
        }
      />
      <ContentContainer>
        <ComponentLoader fullPage active={isLoading} />
        <FormWrapper>
          {formTouched && !termsState && (
            <Notification
              variant='error'
              title='Please check the following 1 error:'
              role='alert'
            >
              <ul>
                <li>
                  <strong>Terms and Conditions</strong>: Please &nbsp;accept the
                  Terms and Condition to continue
                </li>
              </ul>
            </Notification>
          )}
          <p style={{ margin: '0 0 2rem 0' }}>
            Please read and accept the Terms and Conditions before proceeding
            with the transaction.
          </p>

          {/* <FormCheckboxWrapper> */}
          <FormCheckbox
            id='termsAccept'
            name='termsAccept'
            label={
              <span>
                {' '}
                I accept the{' '}
                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                <a
                  role='button'
                  className='termsButton'
                  onClick={() => openModalTermsConditions()}
                  data-testid='terms-btn'
                  tabIndex={0}
                >
                  Terms and Conditions
                </a>
              </span>
            }
            onChange={() => {
              handleCheckbox();
            }}
            checked={termsState}
            value='accept'
            errorMessage='Please accept the Terms and Conditions to continue'
            hasError={formTouched && !termsState}
            testId='termsCheckbox'
          />
          {/* </FormCheckboxWrapper> */}
          <p>
            Our&nbsp;
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            <a
              role='button'
              className='termsButton'
              onClick={() => {
                openInNewTab('https://www.service.nsw.gov.au/privacy');
              }}
              data-testid='privacy-btn'
              tabIndex={0}
            >
              Privacy Statement
            </a>
          </p>
          <SubmitButton
            className={`btn-submit`}
            onClick={() => {
              handleSubmit();
            }}
          >
            Next
          </SubmitButton>
        </FormWrapper>
      </ContentContainer>
      <ModalTermsConditions
        show={modalState.termsConditions}
        onClose={() => closeModal()}
      />
    </>
  );

  const handleCheckbox = (): void => {
    setTermsState(!termsState);
    dispatch(setTermsAndConditionStatus(true));
  };

  const termsAndConditionCheck = (): void => {
    if (!document.cookie.split(';').some((r) => r.includes('tAndC=true'))) {
      document.cookie = 'tAndC=true;';
      dispatch(setTermsAndConditionStatus(true));
    }
  };

  const handleSubmit = (): void => {
    if (valid && globalState.transactionType === TransactionType.DPC) {
      handleButtonClickGA(
        Constants.TERMS_AND_CONDITIONS,
        Constants.PRIMARY_BUTTON_COMPONENT_TYPE,
        pageUrl + Constants.DEMERITS_SUMMARY_PAGE_URL
      );
      history.push(Constants.DEMERITS_SUMMARY_PAGE_URL);
      termsAndConditionCheck();
    } else if (
      valid &&
      globalState.transactionType === TransactionType.ODRCDR
    ) {
      handleButtonClickGA(
        Constants.TERMS_AND_CONDITIONS,
        Constants.PRIMARY_BUTTON_COMPONENT_TYPE,
        pageUrl + Constants.DRIVING_RECORD
      );
      history.push(Constants.DRIVING_RECORD_PAGE_URL);
      termsAndConditionCheck();
    } else {
      setFormTouched(true);
      setValid(false);
    }
  };

  const openModalTermsConditions = (): void =>
    setModalState({
      termsConditions: true,
    });

  function openInNewTab(url) {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
    openModalPrivacyStatement();
  }

  const openModalPrivacyStatement = (): void =>
    setModalState({
      termsConditions: false,
    });

  const closeModal = (): void =>
    setModalState({
      termsConditions: false,
    });

  return isLoading ? (
    <ComponentLoader
      active={isLoading}
      fullPage={true}
      label='One moment please...'
    />
  ) : (
    <TermsWrapper>
      {silentLoginStatus === 'success' && termsConditionsContent}
      <ComponentLoader
        active={silentLoginStatus === 'fetching'}
        fullPage={true}
        label='One moment please...'
      />
    </TermsWrapper>
  );
};

export default TermsCondition;
