import { processPaymentRequest } from 'services/OdrCdrService';
import { IPaymentRequestType } from 'interfaces';
import { history } from 'utils/history';
import { AnalyticsService } from '@rmstransactions/components';
import { Constants } from 'utils/helpers';

// constants
export const paymentRequestConstants = {
  REQUEST_PAYMENT: 'REQUEST_PAYMENT',
  SUCCESS_PAYMENT_REQUEST: 'SUCCESS_PAYMENT_REQUEST',
  FAILURE_PAYMENT_REQUEST: 'FAILURE_PAYMENT_REQUEST',
};

// actions
export const processPaymentsRequest = (value: IPaymentRequestType) => {
    return (dispatch: any) => {
      dispatch(request(value))
      processPaymentRequest(value)
        .then((response) => {
          if (response?.data?.redirectUrl !== '' && response?.data?.messages[0].severity === 'SUCCESS') {
            dispatch(success(response.data, response.headers));
            window.location.href = response.data.redirectUrl;
            return;
          } else {
            dispatch(failure(response.data?.messages[0]));
            AnalyticsService.ErrorReport(
              Constants.SYSTEMS_ERROR,
              Constants.BUISNESS_ERROR,
              response.data?.messages[0],
              new Date(),
            );
            history.push('/error')
          }
        })
        .catch((error) => {
          dispatch(failure(error));
          AnalyticsService.ErrorReportUpgrade(
            Constants.SYSTEMS_ERROR,
            Constants.TECHNICAL_ERROR,
            error,
            new Date(),
            Constants.ERROR_POSITION_BODY
          );
          history.push('/error')
        });
    };

  function request(data: Object) {
    return { type: paymentRequestConstants.REQUEST_PAYMENT, data };
  }
  function success(data: Object, headers: Object) {
    return {
      type: paymentRequestConstants.SUCCESS_PAYMENT_REQUEST,
      data,
      headers,
    };
  }
  function failure(data: Object) {
    return { type: "errorMessages", data }
  };
};

