import React from 'react';
import { Button } from '@snsw/react-component-library';
import { Breakpoint } from '@rmstransactions/components/Styled';
import Styled from 'styled-components';
import { ISubmitButtonPropsType } from 'interfaces';

const SubmitButton: React.FC<ISubmitButtonPropsType> = ({
  className,
  type = 'button',
  onClick = () => {},
  disabled = false,
  children,
}) => {
  const ButtonWrapper = Styled.div`
    margin-top: 2.5rem;

    .formButton {
      width: 100%;

      ${Breakpoint('sm')`
        width: auto;
      `}
    }
  `;

  return (
    <ButtonWrapper>
      <Button
        className={className ? `formButton ${className} ` : 'formButton'}
        type={type}
        onClick={onClick}
        disabled={disabled}
        data-testid='btn-submit'
      >
        {children}
      </Button>
    </ButtonWrapper>
  );
};

export default SubmitButton;
