import { globalStateConstants } from 'redux/actions';
export const initialState = {
  getTransactionType: ''
};

export const globalStateReducer = (state = initialState, action: any) => {

  switch (action.type) {
    case globalStateConstants.SET_TRANSACTION_TYPE:
      return {
        ...state,
        getTransactionType: action.payload,
      }
    default:
      return {
        ...state,
        getTransactionType: 'DPC'
      }
  }
};