import { AppState } from './globalState';

export const reducer = (state: AppState, action: any) => {
  switch (action.type) {
    case 'set_transactionType':
      return {
        ...state,
        transactionType: action.payload,
      };
    case 'set_userId':
        return {
          ...state,
          userId: action.payload,
        };
    case 'set_transactionStartTime':
          return {
            ...state,
            transactionStartTime: action.payload,
          };
    default:
      return { ...state };
  }
};