import { purchaseSuccessConstants } from 'redux/actions';
import { IPurchaseSuccessResponse } from 'interfaces';

export const initialState = {
  status:'',
  isCertified: false,
  mailingAddress: '',
  receiptNumber: '',
  transactionDate: '',
  emailAddress: '',
  totalAmountPaid: 0,
  receiptPDF: '',
  cdrWarning: false,
  customerName: '',
  messages: [],
};

export const purchaseSuccessDrivingRecord = (
  state = initialState,
  payload: IPurchaseSuccessResponse
) => {
  switch (payload.type) {
    case purchaseSuccessConstants.REQUEST_PURCHASE_SUCCESS:
      return {
        ...state,
        status: 'loading',
      };
    case purchaseSuccessConstants.SUCCESS_PURCHASE_SUCCESS:
      return {
        ...state,
        status: 'success',
        isCertified: payload.data.isCertified,
        mailingAddress: payload.data.mailingAddress,
        receiptNumber: payload.data.receiptNumber,
        transactionDate: payload.data.transactionDate,
        emailAddress: payload.data.emailAddress,
        totalAmountPaid: payload.data.totalAmountPaid,
        customerName: payload.data.customerName,
        receiptPDF: payload.data.receiptPDF,
        cdrWarning: payload.data.cdrWarning,
        messages: payload.data.messages,
      };
    case purchaseSuccessConstants.FAILURE_PURCHASE_SUCCESS:
      return {
        ...state,
        status: 'error',
      };
    default:
      return state;
  }
};
