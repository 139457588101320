import React from 'react';
import { H2, Button } from '@snsw/react-component-library';
import { ListStyle } from '@rmstransactions/components/Styled';
import styled from 'styled-components';

const ContentFooterWrapper = styled.div`
  a {
    display: inline;
    font-weight: 400;
  }
`;

const ContentFooter = () => (
  <ContentFooterWrapper>
    <H2>
      You may also like
    </H2>
    <ListStyle>
      <li>
        <Button
          theme='link'
          href='https://www.service.nsw.gov.au/transaction/pay-penalty-or-reminder-notice'
          rel='noopener noreferrer'
          target='_blank'
        >
          Pay a penalty or reminder notice
        </Button>
      </li>
      <li>
        <Button
          theme='link'
          href='https://www.rms.nsw.gov.au/cgi-bin/index.cgi?action=demeritpoints.form'
          rel='noopener noreferrer'
          target='_blank'
          external={true}
        >
          See a list of all demerits point offences
        </Button>
      </li>
      <li>
        <Button
          theme='link'
          href='https://my.service.nsw.gov.au/MyServiceNSW/PersonalDashboard'
          rel='noopener noreferrer'
          target='_blank'
        >
          Return to MyServiceNSW Account
        </Button>
      </li>
    </ListStyle>
  </ContentFooterWrapper>
);

export default ContentFooter;
