import { Config } from 'config/env';
import { isMobileOnly } from 'react-device-detect';
import { DRUPAL_DPC_URL, DRUPAL_UDRCDR_URL } from '../config/env';
import { ODRCDRSession } from '../session/ODRCDRSession';
import ConfirmationUUIDManager from 'session/ConfirmationUUIDManager';
import { UserSession } from '@rmstransactions/components';

type errConstants = {
  [key: string]: string;
};

export const errorResolver = (errorCode: string = '', type: string = '') => {
  if (type === 'demerits') {
    const errorConstants: errConstants = {
      // GENERAL_ERROR
      SYSTEM_FAILURE: 'GENERAL_ERROR',
      INVALID_INPUT: 'GENERAL_ERROR',
      CUSTOMER_NOT_FOUND: 'GENERAL_ERROR',
      DL_PC_NOT_FOUND: 'GENERAL_ERROR',
      // INELIGIBLE
      DL_NOT_FOUND: 'INELIGIBLE',
      CUSTOMER_HAS_CAMS: 'INELIGIBLE',
      CUSTOMER_HAS_ENFORCEMENT: 'INELIGIBLE',
      CUSTOMER_HAS_GBP: 'INELIGIBLE',
      CUSTOMER_HAS_EXPIRED_LIC: 'INELIGIBLE',
      CUSTOMER_LIC_CLASS_NOT_ACTIVE: 'INELIGIBLE',
    };

    return errorConstants[errorCode] || '';
  }

  if (type === 'cdr' || type === 'odr') {
    const errorConstants: errConstants = {
      // GENERAL_ERROR
      SYSTEM_FAILURE: 'GENERAL_ERROR',
      INVALID_LICENCE_ID: 'GENERAL_ERROR',
      INVALID_INPUT: 'GENERAL_ERROR',
      CUSTOMER_NOT_FOUND: 'GENERAL_ERROR',
      CUSTOMER_NOT_INDIVIDUAL: 'GENERAL_ERROR',
      //INELIGIBLE
      CUSTOMER_HAS_CAMS: 'INELIGIBLE',
      CUSTOMER_HAS_ENFORCEMENT: 'INELIGIBLE',
      CUSTOMER_HAS_GBP: 'INELIGIBLE',
      ADDRESS_IS_SUPPRESSED: 'INELIGIBLE',
    };

    return errorConstants[errorCode] || '';
  }

  return '';
};

export const Constants = {
  PURCHASE_SUCCESS_PAGE_URL: '/odr-cdr/success/',
  DRIVING_RECORD_PAGE_URL: '/odr-cdr/drivingRecord',
  DEMERITS_SUMMARY_PAGE_URL: '/demerit-enquiry/summary',
  ODR_CDR_URL: '/odr-cdr',
  DRIVING_RECORD: '/drivingRecord',
  TERMS_CONDITIONS_PAGE: 'Terms and Condition Page',
  DEMERITS_SUMMARY_PAGE: 'Demerit Summary Page',
  PURCHASED_SUCCESS_PAGE: 'Purchased Success Page',
  DRIVING_RECORD_PAGE: 'Driving Record Page',
  SYSTEMS_ERROR: 'System Error',
  TECHNICAL_ERROR: 'Technical Error',
  BUISNESS_ERROR: 'Buisness Error',
  ERROR_POSITION_BODY: 'body',
  QUIT_DPC_URL:
    'https://www.service.nsw.gov.au/transaction/check-your-demerit-points',
  QUIT_ODRCDR_URL:
    'https://www.service.nsw.gov.au/transaction/request-driving-record',
  HELP_US_IMPROVE_DRIVING_RECORD: 'https://forms.office.com/r/KX9K42cDcs',
  TERMS_AND_CONDITIONS: 'Terms and conditions',
  PRIMARY_BUTTON_COMPONENT_TYPE: 'button_primary',
  FORWARD_DOUBLE_SLASH: '//',
};

export const goToLogin = async (transactionType: string = 'DPC') => {
  console.log('transactionType ', transactionType);
  UserSession.login(`${Config.apiHost}/login?state=${transactionType}`);
};

export enum TransactionType {
  ODRCDR = 'ODRCDR',
  DPC = 'DPC',
}

export const TransTypeParmConstants = {
  DEMERIT_ENQUIRY: 'demerit-enquiry',
  ODR_CDR: 'odr-cdr',
};

export const PATH_DRIVING_RECORD = 'drivingRecord';

export const convertToUpperCase = (value: string) => {
  if (isNotEmptyValue(value) && value.length > 1) {
    return value.toString().toUpperCase();
  } else return value;
};

const isNotEmptyValue = (value: string) => {
  return (
    value !== '' &&
    value !== null &&
    value !== undefined &&
    typeof value !== 'undefined'
  );
};

export function base64toBlob(pdf: string) {
  const bytes = atob(pdf);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: 'application/pdf' });
}

export function base64toBlobReceipt(receiptPDF: string) {
  const bytes = atob(receiptPDF);
  let length = bytes.length;
  let out = new Uint8Array(length);
  while (length--) {
    out[length] = bytes.charCodeAt(length);
  }
  return new Blob([out], { type: 'application/pdf' });
}

const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export function getDrivingRecordAccessUntilDate(purchasedDate: string) {
  let accessUntilDate = new Date(purchasedDate);
  accessUntilDate.setDate(accessUntilDate.getDate() + 6);
  const day = accessUntilDate.getDate();
  const monthIndex = accessUntilDate.getMonth();
  const monthName = monthNames[monthIndex];
  const year = accessUntilDate.getFullYear();
  const formattedAccessUntilDate = `${getDayWithSuffix(
    day
  )} ${monthName} ${year}`;
  return formattedAccessUntilDate;
}

export function getDayWithSuffix(day: number) {
  const suffix = ['th', 'st', 'nd', 'rd'],
    v = day % 100;
  return day + (suffix[(v - 20) % 10] || suffix[v] || suffix[0]);
}

export function processPdf(pdf: Blob, fileName: string) {
  const href = window.URL.createObjectURL(pdf);
  const link = document.createElement('a');
  link.href = href;
  link.setAttribute('download', fileName); //or any other extension
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}

export const getAccessibleErrorMessage = (message: string) => {
  if (message) {
    return addAccessibleTextBeforeOpeningNewPages(
      getDeviceBasedDisplayMessage(message)
    );
  } else {
    return message;
  }
};

export const addAccessibleTextBeforeOpeningNewPages = (message: string) => {
  if (message) {
    let newMessage = message.slice();
    let anchorNewPageLinkStartPosition = newMessage.indexOf('_blank');
    if (anchorNewPageLinkStartPosition >= 3) {
      let anchorTagSearchPosition = 0;
      let anchorNewPageEndPosition = -1;
      let continueLoop: boolean = true;
      while (continueLoop) {
        anchorNewPageLinkStartPosition = newMessage.indexOf(
          '_blank',
          anchorTagSearchPosition
        );
        if (anchorNewPageLinkStartPosition >= 0) {
          const anchorOpentagStartPosition = newMessage.indexOf(
            '<a',
            anchorTagSearchPosition
          );
          const nextAnchorOpentagStartPosition = newMessage.indexOf(
            '<a',
            anchorOpentagStartPosition + 1
          );
          const foundBlankInAnchorOpentagStartPosition =
            nextAnchorOpentagStartPosition === -1 ||
            anchorNewPageLinkStartPosition < nextAnchorOpentagStartPosition;
          if (foundBlankInAnchorOpentagStartPosition) {
            anchorNewPageEndPosition = newMessage.indexOf(
              '</a>',
              anchorOpentagStartPosition
            );
            newMessage =
              newMessage.substring(0, anchorNewPageEndPosition) +
              '<div class="sr-only">Opens a new window</div>' +
              newMessage.substring(anchorNewPageEndPosition);
          }
          continueLoop = nextAnchorOpentagStartPosition >= 0;
          if (foundBlankInAnchorOpentagStartPosition) {
            anchorTagSearchPosition = anchorNewPageEndPosition + 31;
          } else {
            anchorTagSearchPosition = nextAnchorOpentagStartPosition;
          }
        } else {
          continueLoop = false;
        }
      }
      return newMessage;
    }
  }
  return message;
};

export const stripTelForNonMobileDevices = (errorMessage: string): string => {
  const telLinkPositionStart = errorMessage.indexOf('tel:');
  if (telLinkPositionStart > 8) {
    const telAnchorOpentagEnd = errorMessage.indexOf('>', telLinkPositionStart);
    const telAnchorClosetagStart = errorMessage.indexOf(
      '</a>',
      telLinkPositionStart
    );
    return (
      errorMessage.substring(0, telLinkPositionStart - 9) +
      errorMessage.substring(telAnchorOpentagEnd + 1, telAnchorClosetagStart) +
      errorMessage.substring(telAnchorClosetagStart + 4)
    );
  }
  return errorMessage;
};

export const getDeviceBasedDisplayMessage = (message: string) => {
  return !isMobileOnly
    ? stripTelForNonMobileDevices(nowrapTelephone(message))
    : nowrapTelephone(message);
};

export const nowrapTelephone = (errorMessage: string) => {
  const telLinkPositionStart = errorMessage.indexOf('tel:');
  if (telLinkPositionStart > 8) {
    const telAnchorClosetagStart = errorMessage.indexOf(
      '</a>',
      telLinkPositionStart
    );
    return (
      errorMessage.substring(0, telLinkPositionStart - 9) +
      '<span style="white-space: nowrap">' +
      errorMessage.substring(
        telLinkPositionStart - 9,
        telAnchorClosetagStart + 4
      ) +
      '</span>' +
      errorMessage.substring(telAnchorClosetagStart + 4)
    );
  }
  return errorMessage;
};

export const cleanupSessionStorage = () => {
  // reset confirmation page UUID
  ConfirmationUUIDManager.clear();
};

export const gotoDrupal = () => {
  cleanupSessionStorage();
  if (ODRCDRSession.getTransactionType() === 'DPC') {
    window.location.href = DRUPAL_DPC_URL;
  } else if (ODRCDRSession.getTransactionType() === 'ODRCDR') {
    window.location.href = DRUPAL_UDRCDR_URL;
  }
};

export const gotoStartPage = () => {
  cleanupSessionStorage();
  if (ODRCDRSession.getTransactionType() === 'DPC') {
    window.location.href = '/';
  } else if (ODRCDRSession.getTransactionType() === 'ODRCDR') {
    window.location.href = '/odr-cdr';
  }
};
