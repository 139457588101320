import styled from 'styled-components';
import { Breakpoint } from '@rmstransactions/components/Styled';

const FormWrapper = styled.div`
  max-width: 100%;
  min-height: 300px;

  ${Breakpoint('md')`
    max-width: 570px;
  `}
`;

export default FormWrapper;
