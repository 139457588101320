export const ODRCDRSession = {
  getCameFromGetYourDrivingRecord() {
    return sessionStorage.getItem('cameFromGetYourDrivingRecord');
  },
  setCameFromGetYourDrivingRecord() {
    sessionStorage.setItem('cameFromGetYourDrivingRecord', 'yes');
  },
  clearCameFromGetYourDrivingRecord(){
    sessionStorage.removeItem('cameFromGetYourDrivingRecord')
  },
  getCameFromBuyDrivingRecord() {
    return sessionStorage.getItem('cameFromBuyDrivingRecord');
  },
  setCameFromBuyDrivingRecord() {
    sessionStorage.setItem('cameFromBuyDrivingRecord', 'yes');
  },
  clearCameFromBuyDrivingRecord(){
    sessionStorage.removeItem('cameFromBuyDrivingRecord')
  },
  getTransactionType() {
    return sessionStorage.getItem('transactionType');
  },
  setTransactionType(transactionType:string) {
    sessionStorage.setItem('transactionType',transactionType);
  },
  clearTransactionType(){
    sessionStorage.removeItem('transactionType')
  },
  clearAll(){
    sessionStorage.removeItem('cameFromGetYourDrivingRecord');
    sessionStorage.removeItem('cameFromBuyDrivingRecord');
    sessionStorage.removeItem('transactionType');
  }
};
