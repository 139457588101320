import axios from 'axios';
import { Config } from 'config/env';
import {HttpRequestHandler, UserToken} from '@rmstransactions/components';
import { IPaymentRequestType, IEmailRequestType } from 'interfaces';

const host = Config.apiHost;
const { apiProcessPaymentPath } = Config;

HttpRequestHandler.setCommonApiKeyHeader()

export const validateOdrCdr = async () => {
  const url = `${host}/auth/validateDrivingRecord`;
  return await axios.get(url, {
    withCredentials: true,
  });
};

export const getDrivingRecordDocument = async (uuid: string) => {
  if (UserToken.getValue()) {
    const url = `${host}/auth/onlineDrivingRecordDocument/${uuid}`;
    return await axios.get(url, {
      withCredentials: true,
      responseType: 'blob',
      headers: { 'x-token': UserToken.getValue() },
    });
  } else {
    const url = `${host}/support/onlineDrivingRecordDocument/${uuid}`;
    return await axios.get(url, {
      withCredentials: false,
      responseType: 'blob',
    });
  }
};

export const sendEmailReceipt = async (
  request: IEmailRequestType
): Promise<any> => {
  if (UserToken.getValue()) {
    const url = `${host}/auth/emailReceipt`;
    return await axios.post(url, request, {
      withCredentials: true,
      headers: { 'x-token': UserToken.getValue() },
    });
  } else {
    const url = `${host}/support/emailReceipt`;
    return await axios.post(url, request, {
      withCredentials: false,
    });
  }
};

export const purchasedSuccess = async (uuid: string) => {
  if (UserToken.getValue()) {
    const url = `${host}/auth/purchasedSuccess/${uuid}`;
    return await axios.get(url, {
      withCredentials: true,
      headers: { 'x-token': UserToken.getValue() },
    });
  } else {
    const url = `${host}/support/purchasedSuccess/${uuid}`;
    return await axios.get(url, {
      withCredentials: false,
    });
  }
};

export const processPaymentRequest = async (
  processPaymentDataRequest: IPaymentRequestType
) => {
  const url = host + apiProcessPaymentPath;
  return await axios.post(url, processPaymentDataRequest, {
    withCredentials: true,
    headers: { 'x-token': UserToken.getValue() },
  });
};
