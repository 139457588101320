import React from 'react';
import { useSelector } from 'react-redux';
import {
  Header,
  Container,
  FormWrapper,
  Spacer,
  DisableTelOnTabletDesktop,
} from '@rmstransactions/components';
import { Button, colors, InPageAlert } from '@snsw/react-component-library';
import styled from 'styled-components';
import { getAccessibleErrorMessage } from 'utils/helpers';

const ErrorPageWrapper = styled.div`
  a {
    color: ${colors.blue};
  }

  .btn__link {
    text-decoration: none;
    font-weight: 700;
  }
`;

const ErrorMessageLayout = styled.span`
  .top-spacing {
    padding-top: 6px;
  }
`;

export const ErrorMessageContainer = styled.span`
     .sr-only {
        position:absolute;
        left:-10000px;
        top:auto;
        width:1px;
        height:1px;
        overflow:hidden;
     }
`;

const ErrorPage = () => {
  const {
    systemMessages,
  } = useSelector((state: any) => ({
    systemMessages: state.errorMessages,
  }));

  return (
    <ErrorPageWrapper>
      <Header
        title={
          systemMessages?.messageDescription
            ? 'Your driving record '
            : "We're sorry about this..."
        }
      />
      <Container pt={4}>
        <FormWrapper maxWidth='760px'>
          <Spacer mb={2.5}>
            <InPageAlert
              title={
                systemMessages?.messageDescription
                  ? systemMessages.messageTitle
                  : "Our system is temporarily unavailable"
              }
              variant='error'
              role='alert'
              className='notice-wrapper'
            >
              {systemMessages?.messageDescription ? (
                <ErrorMessageLayout>
                  <ErrorMessageContainer dangerouslySetInnerHTML={{ __html: getAccessibleErrorMessage(systemMessages?.messageDescription) }} />
                </ErrorMessageLayout>
              ) : (<>
                We need to check a few details before we proceed. Please visit a{' '}
                <a href='https://www.service.nsw.gov.au/service-centre'>
                  service centre
                </a>{' '}
                or call us on <DisableTelOnTabletDesktop phoneNumber='13 77 88' />.
              </>)}
            </InPageAlert>
          </Spacer>
          <Button
            className='btn__link'
            theme='secondary'
            href='https://www.service.nsw.gov.au/transaction/check-your-demerit-points'
            data-testid='button-quit'
          >
            Quit
          </Button>
        </FormWrapper>
      </Container>
    </ErrorPageWrapper>
  );
};

export default ErrorPage;
