import axios from 'axios';
import { Config } from 'config/env';
import { IODRRequestType } from 'interfaces';
import {
  UserToken,
  ErrorService,
  UserSession, HttpRequestHandler,
} from '@rmstransactions/components';

const host = Config.apiHost;
const { apiGetDemeritReportPath } = Config;

export const silentAuthUrl = `${host}/silentLogin?state=`;
export const loginUrl = `${host}/login?state=`;

HttpRequestHandler.setCommonApiKeyHeader()

export const silentLogin = (type) => {
  console.log(`silent Login url ---> ${silentAuthUrl}${type}`);
  UserSession.silentLogin(`${silentAuthUrl}${type}`);
};

export const goToLogin = (type: string) => {
  UserSession.login(`${loginUrl}${type}`);
};

export const getDemeritPointsCheck = async () => {
  const url = host + apiGetDemeritReportPath;
  return await axios.get(url, {
    withCredentials: true,
  });
};

export const getUserDetails = async (): Promise<any> => {
  const url = `${host}/auth/userDetails`;
  return await axios.get(url, {
    withCredentials: true,
  });
};

export const getODRDocumentPDFRequest = async () => {
  const url = `${host}/auth/rms-getOnlineDocument`;
  return await axios.get(url, {
    withCredentials: true,
    headers: { 'x-token': UserToken.getValue() },
  });
};

export const getCustomerDetails = async () => {
  const url = `${host}/auth/rms-getCustomerDetails`;
  console.log('response.headers  UserToken.getValue()' + UserToken.getValue());

  return await axios.get(url, {
    withCredentials: true,
    headers: { 'x-token': UserToken.getValue() },
  });
};

export const sendODRDocumentPDFEmailRequest = async (data: IODRRequestType) => {
  const url = `${host}/auth/rms-emailOnlineDrivingRecord`;
  return await axios.post(url, data, {
    withCredentials: true,
    headers: { 'x-token': UserToken.getValue() },
  });
};

export const forceLogout = async (): Promise<any> => {
  const res = await fetch(`${host}/logout`, {
    credentials: 'include',
  });
  return res;
};

export const interceptors = (
  history: any,
  apiHost: any,
  transactionState: any,
  drupalTransactionLandingPageURL: any
) => {
  HttpRequestHandler.setCommonContentType('application/json')

  axios.interceptors.request.use((x: any) => {
    console.log('axios request', x);
    return x;
  });

  axios.interceptors.response.use(
    async (x: any) => {
      console.log('axios response', x);
      return x;
    },
    (y: any) => {
      console.log('axios error', y);
      ErrorService.handleAPIErrorForAuthTrans(
        y,
        history,
        apiHost,
        transactionState,
        drupalTransactionLandingPageURL
      );
    }
  );
};
