import { getDemeritPointsCheck } from 'services/DemeritPointsService';
import {UserToken, AnalyticsService, UserSession, ErrorService} from '@rmstransactions/components';
import {Constants, TransactionType} from 'utils/helpers';
import {Config, DRUPAL_DPC_URL} from "../../config/env";
import { history } from "../../utils";

// constants
export const loginAndDemeritsReportConstants = {
  REQUEST_DEMERITS_REPORT: 'REQUEST_DEMERITS_REPORT',
  SUCCESS_DEMERITS_REPORT: 'SUCCESS_DEMERITS_REPORT',
  FAILTURE_DEMERITS_REPORT: 'FAILTURE_DEMERITS_REPORT',
  SET_LOGIN_STATUS: 'SET_LOGIN_STATUS',
};

export type LoginStatus = 'success' | 'failure' | 'fetching';

// actions
export function getDemeritsReport() {
  return (dispatch: any) => {
    dispatch(request());
    getDemeritPointsCheck()
      .then((response) => {
        UserToken.setValue(response.headers['x-token']);
        dispatch(success(response.data, response.headers));
      })
      .catch((error) => {
        if(error.message.includes('401')){
          let error = { message: '401 Unauthorized' };
          UserSession.resetActiveSession();
          ErrorService.handleAPIErrorForAuthTrans(
            error,
            history,
            Config.apiHost,
            TransactionType.DPC,
            DRUPAL_DPC_URL
          );
        }
        dispatch(failure(error));
        AnalyticsService.ErrorReportUpgrade(
          Constants.SYSTEMS_ERROR,
          Constants.TECHNICAL_ERROR,
          error,
          new Date(),
          Constants.ERROR_POSITION_BODY
        );
      });
  };

  function request(data?: Object) {
    return {
      type: loginAndDemeritsReportConstants.REQUEST_DEMERITS_REPORT,
      data,
    };
  }
  function success(data: Object, headers: Object) {
    return {
      type: loginAndDemeritsReportConstants.SUCCESS_DEMERITS_REPORT,
      data,
      headers,
    };
  }
  function failure(data: Object) {
    return {
      type: loginAndDemeritsReportConstants.FAILTURE_DEMERITS_REPORT,
      data,
    };
  }
}

export function setLoginStatus(data: Object) {
  return {
    type: loginAndDemeritsReportConstants.SET_LOGIN_STATUS,
    data,
  };
}
